body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.flip-btn.btn-default, .flip-btn.btn-default:focus, .flip-btn.btn-default:hover, .flip-btn.btn-default:active, .flip-btn.btn-default:hover:active {
  background-color: #0000;
  border-width: 0;
  border-color: #0000;
  line-height: 1;
}

.flip-btn.btn-default .fa {
  line-height: 1.4;
}

.flip-btn.btn-default:hover {
  background-color: #0000;
}

.flip-btn.btn-default span {
  color: #444;
  background-color: #fff;
  border: 1px solid #444;
  line-height: 1;
}

.flip-btn.btn-default:focus span, .flip-btn.btn-default.focus span {
  color: #444;
  background-color: #fff;
  border-color: #848484;
}

.flip-btn.btn-default:hover span, .flip-btn.btn-default:active span, .flip-btn.btn-default.active span, .open > .dropdown-toggle.flip-btn.btn-default span {
  color: #444;
  background-color: #fff;
  border-color: #636363;
}

.flip-btn.btn-default:active span:hover span, .flip-btn.btn-default.active span:hover span, .open > .dropdown-toggle.flip-btn.btn-default span:hover span, .flip-btn.btn-default:active span:focus span, .flip-btn.btn-default.active span:focus span, .open > .dropdown-toggle.flip-btn.btn-default span:focus span, .flip-btn.btn-default:active span.focus span, .flip-btn.btn-default.active span.focus span, .open > .dropdown-toggle.flip-btn.btn-default span.focus span {
  color: #444;
  background-color: #fff;
  border-color: #848484;
}

.flip-btn.btn-default:active span, .flip-btn.btn-default.active span, .open > .dropdown-toggle.flip-btn.btn-default span {
  background-image: none;
}

.flip-btn.btn-default.disabled span, .flip-btn.btn-default[disabled] span, fieldset[disabled] .flip-btn.btn-default span span, .flip-btn.btn-default.disabled:hover span, .flip-btn.btn-default[disabled]:hover span, fieldset[disabled] .flip-btn.btn-default span:hover span, .flip-btn.btn-default.disabled:focus span, .flip-btn.btn-default[disabled]:focus span, fieldset[disabled] .flip-btn.btn-default span:focus span, .flip-btn.btn-default.disabled.focus span, .flip-btn.btn-default[disabled].focus span, fieldset[disabled] .flip-btn.btn-default span.focus span, .flip-btn.btn-default.disabled:active span, .flip-btn.btn-default[disabled]:active span, fieldset[disabled] .flip-btn.btn-default span:active span, .flip-btn.btn-default.disabled.active span, .flip-btn.btn-default[disabled].active span, fieldset[disabled] .flip-btn.btn-default span.active span {
  background-color: #fff;
  border-color: #444;
}

.flip-btn.btn-default .badge span {
  color: #fff;
  background-color: #444;
}

.flip-btn.btn-primary, .flip-btn.btn-primary:focus, .flip-btn.btn-primary:hover, .flip-btn.btn-primary:active, .flip-btn.btn-primary:hover:active {
  background-color: #0000;
  border-width: 0;
  border-color: #0000;
  line-height: 1;
}

.flip-btn.btn-primary .fa {
  line-height: 1.4;
}

.flip-btn.btn-primary:hover {
  background-color: #0000;
}

.flip-btn.btn-primary span {
  color: #fff;
  background-color: #2a4c82;
  border: 1px solid #182a48;
  line-height: 1;
}

.flip-btn.btn-primary:focus span, .flip-btn.btn-primary.focus span {
  color: #fff;
  background-color: #3763a8;
  border-color: #3763a8;
}

.flip-btn.btn-primary:hover span, .flip-btn.btn-primary:active span, .flip-btn.btn-primary.active span, .open > .dropdown-toggle.flip-btn.btn-primary span {
  color: #fff;
  background-color: #3763a8;
  border-color: #274676;
}

.flip-btn.btn-primary:active span:hover span, .flip-btn.btn-primary.active span:hover span, .open > .dropdown-toggle.flip-btn.btn-primary span:hover span, .flip-btn.btn-primary:active span:focus span, .flip-btn.btn-primary.active span:focus span, .open > .dropdown-toggle.flip-btn.btn-primary span:focus span, .flip-btn.btn-primary:active span.focus span, .flip-btn.btn-primary.active span.focus span, .open > .dropdown-toggle.flip-btn.btn-primary span.focus span {
  color: #fff;
  background-color: #4273c1;
  border-color: #3763a8;
}

.flip-btn.btn-primary:active span, .flip-btn.btn-primary.active span, .open > .dropdown-toggle.flip-btn.btn-primary span {
  background-image: none;
}

.flip-btn.btn-primary.disabled span, .flip-btn.btn-primary[disabled] span, fieldset[disabled] .flip-btn.btn-primary span span, .flip-btn.btn-primary.disabled:hover span, .flip-btn.btn-primary[disabled]:hover span, fieldset[disabled] .flip-btn.btn-primary span:hover span, .flip-btn.btn-primary.disabled:focus span, .flip-btn.btn-primary[disabled]:focus span, fieldset[disabled] .flip-btn.btn-primary span:focus span, .flip-btn.btn-primary.disabled.focus span, .flip-btn.btn-primary[disabled].focus span, fieldset[disabled] .flip-btn.btn-primary span.focus span, .flip-btn.btn-primary.disabled:active span, .flip-btn.btn-primary[disabled]:active span, fieldset[disabled] .flip-btn.btn-primary span:active span, .flip-btn.btn-primary.disabled.active span, .flip-btn.btn-primary[disabled].active span, fieldset[disabled] .flip-btn.btn-primary span.active span {
  background-color: #2a4c82;
  border-color: #182a48;
}

.flip-btn.btn-primary .badge span {
  color: #2a4c82;
  background-color: #fff;
}

.flip-btn.btn-success, .flip-btn.btn-success:focus, .flip-btn.btn-success:hover, .flip-btn.btn-success:active, .flip-btn.btn-success:hover:active {
  background-color: #0000;
  border-width: 0;
  border-color: #0000;
  line-height: 1;
}

.flip-btn.btn-success .fa {
  line-height: 1.4;
}

.flip-btn.btn-success:hover {
  background-color: #0000;
}

.flip-btn.btn-success span {
  color: #fff;
  background-color: #71a844;
  border: 1px solid #4d722e;
  line-height: 1;
}

.flip-btn.btn-success:focus span, .flip-btn.btn-success.focus span {
  color: #fff;
  background-color: #8bbf60;
  border-color: #8bbf60;
}

.flip-btn.btn-success:hover span, .flip-btn.btn-success:active span, .flip-btn.btn-success.active span, .open > .dropdown-toggle.flip-btn.btn-success span {
  color: #fff;
  background-color: #8bbf60;
  border-color: #6a9d3f;
}

.flip-btn.btn-success:active span:hover span, .flip-btn.btn-success.active span:hover span, .open > .dropdown-toggle.flip-btn.btn-success span:hover span, .flip-btn.btn-success:active span:focus span, .flip-btn.btn-success.active span:focus span, .open > .dropdown-toggle.flip-btn.btn-success span:focus span, .flip-btn.btn-success:active span.focus span, .flip-btn.btn-success.active span.focus span, .open > .dropdown-toggle.flip-btn.btn-success span.focus span {
  color: #fff;
  background-color: #9ec979;
  border-color: #8bbf60;
}

.flip-btn.btn-success:active span, .flip-btn.btn-success.active span, .open > .dropdown-toggle.flip-btn.btn-success span {
  background-image: none;
}

.flip-btn.btn-success.disabled span, .flip-btn.btn-success[disabled] span, fieldset[disabled] .flip-btn.btn-success span span, .flip-btn.btn-success.disabled:hover span, .flip-btn.btn-success[disabled]:hover span, fieldset[disabled] .flip-btn.btn-success span:hover span, .flip-btn.btn-success.disabled:focus span, .flip-btn.btn-success[disabled]:focus span, fieldset[disabled] .flip-btn.btn-success span:focus span, .flip-btn.btn-success.disabled.focus span, .flip-btn.btn-success[disabled].focus span, fieldset[disabled] .flip-btn.btn-success span.focus span, .flip-btn.btn-success.disabled:active span, .flip-btn.btn-success[disabled]:active span, fieldset[disabled] .flip-btn.btn-success span:active span, .flip-btn.btn-success.disabled.active span, .flip-btn.btn-success[disabled].active span, fieldset[disabled] .flip-btn.btn-success span.active span {
  background-color: #71a844;
  border-color: #4d722e;
}

.flip-btn.btn-success .badge span {
  color: #71a844;
  background-color: #fff;
}

.flip-btn.btn-info, .flip-btn.btn-info:focus, .flip-btn.btn-info:hover, .flip-btn.btn-info:active, .flip-btn.btn-info:hover:active {
  background-color: #0000;
  border-width: 0;
  border-color: #0000;
  line-height: 1;
}

.flip-btn.btn-info .fa {
  line-height: 1.4;
}

.flip-btn.btn-info:hover {
  background-color: #0000;
}

.flip-btn.btn-info span {
  color: #fff;
  background-color: #3c3c3c;
  border: 1px solid #161616;
  line-height: 1;
}

.flip-btn.btn-info:focus span, .flip-btn.btn-info.focus span {
  color: #fff;
  background-color: #555;
  border-color: #555;
}

.flip-btn.btn-info:hover span, .flip-btn.btn-info:active span, .flip-btn.btn-info.active span, .open > .dropdown-toggle.flip-btn.btn-info span {
  color: #fff;
  background-color: #555;
  border-color: #343434;
}

.flip-btn.btn-info:active span:hover span, .flip-btn.btn-info.active span:hover span, .open > .dropdown-toggle.flip-btn.btn-info span:hover span, .flip-btn.btn-info:active span:focus span, .flip-btn.btn-info.active span:focus span, .open > .dropdown-toggle.flip-btn.btn-info span:focus span, .flip-btn.btn-info:active span.focus span, .flip-btn.btn-info.active span.focus span, .open > .dropdown-toggle.flip-btn.btn-info span.focus span {
  color: #fff;
  background-color: #676767;
  border-color: #555;
}

.flip-btn.btn-info:active span, .flip-btn.btn-info.active span, .open > .dropdown-toggle.flip-btn.btn-info span {
  background-image: none;
}

.flip-btn.btn-info.disabled span, .flip-btn.btn-info[disabled] span, fieldset[disabled] .flip-btn.btn-info span span, .flip-btn.btn-info.disabled:hover span, .flip-btn.btn-info[disabled]:hover span, fieldset[disabled] .flip-btn.btn-info span:hover span, .flip-btn.btn-info.disabled:focus span, .flip-btn.btn-info[disabled]:focus span, fieldset[disabled] .flip-btn.btn-info span:focus span, .flip-btn.btn-info.disabled.focus span, .flip-btn.btn-info[disabled].focus span, fieldset[disabled] .flip-btn.btn-info span.focus span, .flip-btn.btn-info.disabled:active span, .flip-btn.btn-info[disabled]:active span, fieldset[disabled] .flip-btn.btn-info span:active span, .flip-btn.btn-info.disabled.active span, .flip-btn.btn-info[disabled].active span, fieldset[disabled] .flip-btn.btn-info span.active span {
  background-color: #3c3c3c;
  border-color: #161616;
}

.flip-btn.btn-info .badge span {
  color: #3c3c3c;
  background-color: #fff;
}

.flip-btn.btn-warning, .flip-btn.btn-warning:focus, .flip-btn.btn-warning:hover, .flip-btn.btn-warning:active, .flip-btn.btn-warning:hover:active {
  background-color: #0000;
  border-width: 0;
  border-color: #0000;
  line-height: 1;
}

.flip-btn.btn-warning .fa {
  line-height: 1.4;
}

.flip-btn.btn-warning:hover {
  background-color: #0000;
}

.flip-btn.btn-warning span {
  color: #fff;
  background-color: #82702a;
  border: 1px solid #483e18;
  line-height: 1;
}

.flip-btn.btn-warning:focus span, .flip-btn.btn-warning.focus span {
  color: #fff;
  background-color: #a89137;
  border-color: #a89137;
}

.flip-btn.btn-warning:hover span, .flip-btn.btn-warning:active span, .flip-btn.btn-warning.active span, .open > .dropdown-toggle.flip-btn.btn-warning span {
  color: #fff;
  background-color: #a89137;
  border-color: #766627;
}

.flip-btn.btn-warning:active span:hover span, .flip-btn.btn-warning.active span:hover span, .open > .dropdown-toggle.flip-btn.btn-warning span:hover span, .flip-btn.btn-warning:active span:focus span, .flip-btn.btn-warning.active span:focus span, .open > .dropdown-toggle.flip-btn.btn-warning span:focus span, .flip-btn.btn-warning:active span.focus span, .flip-btn.btn-warning.active span.focus span, .open > .dropdown-toggle.flip-btn.btn-warning span.focus span {
  color: #fff;
  background-color: #c1a742;
  border-color: #a89137;
}

.flip-btn.btn-warning:active span, .flip-btn.btn-warning.active span, .open > .dropdown-toggle.flip-btn.btn-warning span {
  background-image: none;
}

.flip-btn.btn-warning.disabled span, .flip-btn.btn-warning[disabled] span, fieldset[disabled] .flip-btn.btn-warning span span, .flip-btn.btn-warning.disabled:hover span, .flip-btn.btn-warning[disabled]:hover span, fieldset[disabled] .flip-btn.btn-warning span:hover span, .flip-btn.btn-warning.disabled:focus span, .flip-btn.btn-warning[disabled]:focus span, fieldset[disabled] .flip-btn.btn-warning span:focus span, .flip-btn.btn-warning.disabled.focus span, .flip-btn.btn-warning[disabled].focus span, fieldset[disabled] .flip-btn.btn-warning span.focus span, .flip-btn.btn-warning.disabled:active span, .flip-btn.btn-warning[disabled]:active span, fieldset[disabled] .flip-btn.btn-warning span:active span, .flip-btn.btn-warning.disabled.active span, .flip-btn.btn-warning[disabled].active span, fieldset[disabled] .flip-btn.btn-warning span.active span {
  background-color: #82702a;
  border-color: #483e18;
}

.flip-btn.btn-warning .badge span {
  color: #82702a;
  background-color: #fff;
}

.flip-btn.btn-danger, .flip-btn.btn-danger:focus, .flip-btn.btn-danger:hover, .flip-btn.btn-danger:active, .flip-btn.btn-danger:hover:active {
  background-color: #0000;
  border-width: 0;
  border-color: #0000;
  line-height: 1;
}

.flip-btn.btn-danger .fa {
  line-height: 1.4;
}

.flip-btn.btn-danger:hover {
  background-color: #0000;
}

.flip-btn.btn-danger span {
  color: #fff;
  background-color: #300b0b;
  border: 1px solid #000;
  line-height: 1;
}

.flip-btn.btn-danger:focus span, .flip-btn.btn-danger.focus span {
  color: #fff;
  background-color: #5a1414;
  border-color: #404040;
}

.flip-btn.btn-danger:hover span, .flip-btn.btn-danger:active span, .flip-btn.btn-danger.active span, .open > .dropdown-toggle.flip-btn.btn-danger span {
  color: #fff;
  background-color: #5a1414;
  border-color: #1f1f1f;
}

.flip-btn.btn-danger:active span:hover span, .flip-btn.btn-danger.active span:hover span, .open > .dropdown-toggle.flip-btn.btn-danger span:hover span, .flip-btn.btn-danger:active span:focus span, .flip-btn.btn-danger.active span:focus span, .open > .dropdown-toggle.flip-btn.btn-danger span:focus span, .flip-btn.btn-danger:active span.focus span, .flip-btn.btn-danger.active span.focus span, .open > .dropdown-toggle.flip-btn.btn-danger span.focus span {
  color: #fff;
  background-color: #771a1a;
  border-color: #404040;
}

.flip-btn.btn-danger:active span, .flip-btn.btn-danger.active span, .open > .dropdown-toggle.flip-btn.btn-danger span {
  background-image: none;
}

.flip-btn.btn-danger.disabled span, .flip-btn.btn-danger[disabled] span, fieldset[disabled] .flip-btn.btn-danger span span, .flip-btn.btn-danger.disabled:hover span, .flip-btn.btn-danger[disabled]:hover span, fieldset[disabled] .flip-btn.btn-danger span:hover span, .flip-btn.btn-danger.disabled:focus span, .flip-btn.btn-danger[disabled]:focus span, fieldset[disabled] .flip-btn.btn-danger span:focus span, .flip-btn.btn-danger.disabled.focus span, .flip-btn.btn-danger[disabled].focus span, fieldset[disabled] .flip-btn.btn-danger span.focus span, .flip-btn.btn-danger.disabled:active span, .flip-btn.btn-danger[disabled]:active span, fieldset[disabled] .flip-btn.btn-danger span:active span, .flip-btn.btn-danger.disabled.active span, .flip-btn.btn-danger[disabled].active span, fieldset[disabled] .flip-btn.btn-danger span.active span {
  background-color: #300b0b;
  border-color: #000;
}

.flip-btn.btn-danger .badge span {
  color: #300b0b;
  background-color: #fff;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    text-shadow: none !important;
    box-shadow: none !important;
    background: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  .navbar {
    display: none;
  }

  .btn > .caret, .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }

  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered th, .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

@font-face {
  font-family: Glyphicons Halflings;
  src: url("glyphicons-halflings-regular.cca02454.eot");
  src: url("glyphicons-halflings-regular.cca02454.eot#iefix") format("embedded-opentype"), url("glyphicons-halflings-regular.a3cea8f3.woff2") format("woff2"), url("glyphicons-halflings-regular.3a458e4a.woff") format("woff"), url("glyphicons-halflings-regular.1a33c1eb.ttf") format("truetype"), url("glyphicons-halflings-regular.6e7c7fa3.svg#glyphicons_halflingsregular") format("svg");
}

.glyphicon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Glyphicons Halflings;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  position: relative;
  top: 1px;
}

.glyphicon-asterisk:before {
  content: "*";
}

.glyphicon-plus:before {
  content: "+";
}

.glyphicon-euro:before, .glyphicon-eur:before {
  content: "€";
}

.glyphicon-minus:before {
  content: "−";
}

.glyphicon-cloud:before {
  content: "☁";
}

.glyphicon-envelope:before {
  content: "✉";
}

.glyphicon-pencil:before {
  content: "✏";
}

.glyphicon-glass:before {
  content: "";
}

.glyphicon-music:before {
  content: "";
}

.glyphicon-search:before {
  content: "";
}

.glyphicon-heart:before {
  content: "";
}

.glyphicon-star:before {
  content: "";
}

.glyphicon-star-empty:before {
  content: "";
}

.glyphicon-user:before {
  content: "";
}

.glyphicon-film:before {
  content: "";
}

.glyphicon-th-large:before {
  content: "";
}

.glyphicon-th:before {
  content: "";
}

.glyphicon-th-list:before {
  content: "";
}

.glyphicon-ok:before {
  content: "";
}

.glyphicon-remove:before {
  content: "";
}

.glyphicon-zoom-in:before {
  content: "";
}

.glyphicon-zoom-out:before {
  content: "";
}

.glyphicon-off:before {
  content: "";
}

.glyphicon-signal:before {
  content: "";
}

.glyphicon-cog:before {
  content: "";
}

.glyphicon-trash:before {
  content: "";
}

.glyphicon-home:before {
  content: "";
}

.glyphicon-file:before {
  content: "";
}

.glyphicon-time:before {
  content: "";
}

.glyphicon-road:before {
  content: "";
}

.glyphicon-download-alt:before {
  content: "";
}

.glyphicon-download:before {
  content: "";
}

.glyphicon-upload:before {
  content: "";
}

.glyphicon-inbox:before {
  content: "";
}

.glyphicon-play-circle:before {
  content: "";
}

.glyphicon-repeat:before {
  content: "";
}

.glyphicon-refresh:before {
  content: "";
}

.glyphicon-list-alt:before {
  content: "";
}

.glyphicon-lock:before {
  content: "";
}

.glyphicon-flag:before {
  content: "";
}

.glyphicon-headphones:before {
  content: "";
}

.glyphicon-volume-off:before {
  content: "";
}

.glyphicon-volume-down:before {
  content: "";
}

.glyphicon-volume-up:before {
  content: "";
}

.glyphicon-qrcode:before {
  content: "";
}

.glyphicon-barcode:before {
  content: "";
}

.glyphicon-tag:before {
  content: "";
}

.glyphicon-tags:before {
  content: "";
}

.glyphicon-book:before {
  content: "";
}

.glyphicon-bookmark:before {
  content: "";
}

.glyphicon-print:before {
  content: "";
}

.glyphicon-camera:before {
  content: "";
}

.glyphicon-font:before {
  content: "";
}

.glyphicon-bold:before {
  content: "";
}

.glyphicon-italic:before {
  content: "";
}

.glyphicon-text-height:before {
  content: "";
}

.glyphicon-text-width:before {
  content: "";
}

.glyphicon-align-left:before {
  content: "";
}

.glyphicon-align-center:before {
  content: "";
}

.glyphicon-align-right:before {
  content: "";
}

.glyphicon-align-justify:before {
  content: "";
}

.glyphicon-list:before {
  content: "";
}

.glyphicon-indent-left:before {
  content: "";
}

.glyphicon-indent-right:before {
  content: "";
}

.glyphicon-facetime-video:before {
  content: "";
}

.glyphicon-picture:before {
  content: "";
}

.glyphicon-map-marker:before {
  content: "";
}

.glyphicon-adjust:before {
  content: "";
}

.glyphicon-tint:before {
  content: "";
}

.glyphicon-edit:before {
  content: "";
}

.glyphicon-share:before {
  content: "";
}

.glyphicon-check:before {
  content: "";
}

.glyphicon-move:before {
  content: "";
}

.glyphicon-step-backward:before {
  content: "";
}

.glyphicon-fast-backward:before {
  content: "";
}

.glyphicon-backward:before {
  content: "";
}

.glyphicon-play:before {
  content: "";
}

.glyphicon-pause:before {
  content: "";
}

.glyphicon-stop:before {
  content: "";
}

.glyphicon-forward:before {
  content: "";
}

.glyphicon-fast-forward:before {
  content: "";
}

.glyphicon-step-forward:before {
  content: "";
}

.glyphicon-eject:before {
  content: "";
}

.glyphicon-chevron-left:before {
  content: "";
}

.glyphicon-chevron-right:before {
  content: "";
}

.glyphicon-plus-sign:before {
  content: "";
}

.glyphicon-minus-sign:before {
  content: "";
}

.glyphicon-remove-sign:before {
  content: "";
}

.glyphicon-ok-sign:before {
  content: "";
}

.glyphicon-question-sign:before {
  content: "";
}

.glyphicon-info-sign:before {
  content: "";
}

.glyphicon-screenshot:before {
  content: "";
}

.glyphicon-remove-circle:before {
  content: "";
}

.glyphicon-ok-circle:before {
  content: "";
}

.glyphicon-ban-circle:before {
  content: "";
}

.glyphicon-arrow-left:before {
  content: "";
}

.glyphicon-arrow-right:before {
  content: "";
}

.glyphicon-arrow-up:before {
  content: "";
}

.glyphicon-arrow-down:before {
  content: "";
}

.glyphicon-share-alt:before {
  content: "";
}

.glyphicon-resize-full:before {
  content: "";
}

.glyphicon-resize-small:before {
  content: "";
}

.glyphicon-exclamation-sign:before {
  content: "";
}

.glyphicon-gift:before {
  content: "";
}

.glyphicon-leaf:before {
  content: "";
}

.glyphicon-fire:before {
  content: "";
}

.glyphicon-eye-open:before {
  content: "";
}

.glyphicon-eye-close:before {
  content: "";
}

.glyphicon-warning-sign:before {
  content: "";
}

.glyphicon-plane:before {
  content: "";
}

.glyphicon-calendar:before {
  content: "";
}

.glyphicon-random:before {
  content: "";
}

.glyphicon-comment:before {
  content: "";
}

.glyphicon-magnet:before {
  content: "";
}

.glyphicon-chevron-up:before {
  content: "";
}

.glyphicon-chevron-down:before {
  content: "";
}

.glyphicon-retweet:before {
  content: "";
}

.glyphicon-shopping-cart:before {
  content: "";
}

.glyphicon-folder-close:before {
  content: "";
}

.glyphicon-folder-open:before {
  content: "";
}

.glyphicon-resize-vertical:before {
  content: "";
}

.glyphicon-resize-horizontal:before {
  content: "";
}

.glyphicon-hdd:before {
  content: "";
}

.glyphicon-bullhorn:before {
  content: "";
}

.glyphicon-bell:before {
  content: "";
}

.glyphicon-certificate:before {
  content: "";
}

.glyphicon-thumbs-up:before {
  content: "";
}

.glyphicon-thumbs-down:before {
  content: "";
}

.glyphicon-hand-right:before {
  content: "";
}

.glyphicon-hand-left:before {
  content: "";
}

.glyphicon-hand-up:before {
  content: "";
}

.glyphicon-hand-down:before {
  content: "";
}

.glyphicon-circle-arrow-right:before {
  content: "";
}

.glyphicon-circle-arrow-left:before {
  content: "";
}

.glyphicon-circle-arrow-up:before {
  content: "";
}

.glyphicon-circle-arrow-down:before {
  content: "";
}

.glyphicon-globe:before {
  content: "";
}

.glyphicon-wrench:before {
  content: "";
}

.glyphicon-tasks:before {
  content: "";
}

.glyphicon-filter:before {
  content: "";
}

.glyphicon-briefcase:before {
  content: "";
}

.glyphicon-fullscreen:before {
  content: "";
}

.glyphicon-dashboard:before {
  content: "";
}

.glyphicon-paperclip:before {
  content: "";
}

.glyphicon-heart-empty:before {
  content: "";
}

.glyphicon-link:before {
  content: "";
}

.glyphicon-phone:before {
  content: "";
}

.glyphicon-pushpin:before {
  content: "";
}

.glyphicon-usd:before {
  content: "";
}

.glyphicon-gbp:before {
  content: "";
}

.glyphicon-sort:before {
  content: "";
}

.glyphicon-sort-by-alphabet:before {
  content: "";
}

.glyphicon-sort-by-alphabet-alt:before {
  content: "";
}

.glyphicon-sort-by-order:before {
  content: "";
}

.glyphicon-sort-by-order-alt:before {
  content: "";
}

.glyphicon-sort-by-attributes:before {
  content: "";
}

.glyphicon-sort-by-attributes-alt:before {
  content: "";
}

.glyphicon-unchecked:before {
  content: "";
}

.glyphicon-expand:before {
  content: "";
}

.glyphicon-collapse-down:before {
  content: "";
}

.glyphicon-collapse-up:before {
  content: "";
}

.glyphicon-log-in:before {
  content: "";
}

.glyphicon-flash:before {
  content: "";
}

.glyphicon-log-out:before {
  content: "";
}

.glyphicon-new-window:before {
  content: "";
}

.glyphicon-record:before {
  content: "";
}

.glyphicon-save:before {
  content: "";
}

.glyphicon-open:before {
  content: "";
}

.glyphicon-saved:before {
  content: "";
}

.glyphicon-import:before {
  content: "";
}

.glyphicon-export:before {
  content: "";
}

.glyphicon-send:before {
  content: "";
}

.glyphicon-floppy-disk:before {
  content: "";
}

.glyphicon-floppy-saved:before {
  content: "";
}

.glyphicon-floppy-remove:before {
  content: "";
}

.glyphicon-floppy-save:before {
  content: "";
}

.glyphicon-floppy-open:before {
  content: "";
}

.glyphicon-credit-card:before {
  content: "";
}

.glyphicon-transfer:before {
  content: "";
}

.glyphicon-cutlery:before {
  content: "";
}

.glyphicon-header:before {
  content: "";
}

.glyphicon-compressed:before {
  content: "";
}

.glyphicon-earphone:before {
  content: "";
}

.glyphicon-phone-alt:before {
  content: "";
}

.glyphicon-tower:before {
  content: "";
}

.glyphicon-stats:before {
  content: "";
}

.glyphicon-sd-video:before {
  content: "";
}

.glyphicon-hd-video:before {
  content: "";
}

.glyphicon-subtitles:before {
  content: "";
}

.glyphicon-sound-stereo:before {
  content: "";
}

.glyphicon-sound-dolby:before {
  content: "";
}

.glyphicon-sound-5-1:before {
  content: "";
}

.glyphicon-sound-6-1:before {
  content: "";
}

.glyphicon-sound-7-1:before {
  content: "";
}

.glyphicon-copyright-mark:before {
  content: "";
}

.glyphicon-registration-mark:before {
  content: "";
}

.glyphicon-cloud-download:before {
  content: "";
}

.glyphicon-cloud-upload:before {
  content: "";
}

.glyphicon-tree-conifer:before {
  content: "";
}

.glyphicon-tree-deciduous:before {
  content: "";
}

.glyphicon-cd:before {
  content: "";
}

.glyphicon-save-file:before {
  content: "";
}

.glyphicon-open-file:before {
  content: "";
}

.glyphicon-level-up:before {
  content: "";
}

.glyphicon-copy:before {
  content: "";
}

.glyphicon-paste:before {
  content: "";
}

.glyphicon-alert:before {
  content: "";
}

.glyphicon-equalizer:before {
  content: "";
}

.glyphicon-king:before {
  content: "";
}

.glyphicon-queen:before {
  content: "";
}

.glyphicon-pawn:before {
  content: "";
}

.glyphicon-bishop:before {
  content: "";
}

.glyphicon-knight:before {
  content: "";
}

.glyphicon-baby-formula:before {
  content: "";
}

.glyphicon-tent:before {
  content: "⛺";
}

.glyphicon-blackboard:before {
  content: "";
}

.glyphicon-bed:before {
  content: "";
}

.glyphicon-apple:before {
  content: "";
}

.glyphicon-erase:before {
  content: "";
}

.glyphicon-hourglass:before {
  content: "⌛";
}

.glyphicon-lamp:before {
  content: "";
}

.glyphicon-duplicate:before {
  content: "";
}

.glyphicon-piggy-bank:before {
  content: "";
}

.glyphicon-scissors:before {
  content: "";
}

.glyphicon-bitcoin:before, .glyphicon-btc:before, .glyphicon-xbt:before {
  content: "";
}

.glyphicon-yen:before, .glyphicon-jpy:before {
  content: "¥";
}

.glyphicon-ruble:before, .glyphicon-rub:before {
  content: "₽";
}

.glyphicon-scale:before {
  content: "";
}

.glyphicon-ice-lolly:before {
  content: "";
}

.glyphicon-ice-lolly-tasted:before {
  content: "";
}

.glyphicon-education:before {
  content: "";
}

.glyphicon-option-horizontal:before {
  content: "";
}

.glyphicon-option-vertical:before {
  content: "";
}

.glyphicon-menu-hamburger:before {
  content: "";
}

.glyphicon-modal-window:before {
  content: "";
}

.glyphicon-oil:before {
  content: "";
}

.glyphicon-grain:before {
  content: "";
}

.glyphicon-sunglasses:before {
  content: "";
}

.glyphicon-text-size:before {
  content: "";
}

.glyphicon-text-color:before {
  content: "";
}

.glyphicon-text-background:before {
  content: "";
}

.glyphicon-object-align-top:before {
  content: "";
}

.glyphicon-object-align-bottom:before {
  content: "";
}

.glyphicon-object-align-horizontal:before {
  content: "";
}

.glyphicon-object-align-left:before {
  content: "";
}

.glyphicon-object-align-vertical:before {
  content: "";
}

.glyphicon-object-align-right:before {
  content: "";
}

.glyphicon-triangle-right:before {
  content: "";
}

.glyphicon-triangle-left:before {
  content: "";
}

.glyphicon-triangle-bottom:before {
  content: "";
}

.glyphicon-triangle-top:before {
  content: "";
}

.glyphicon-console:before {
  content: "";
}

.glyphicon-superscript:before {
  content: "";
}

.glyphicon-subscript:before {
  content: "";
}

.glyphicon-menu-left:before {
  content: "";
}

.glyphicon-menu-right:before {
  content: "";
}

.glyphicon-menu-down:before {
  content: "";
}

.glyphicon-menu-up:before {
  content: "";
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: #0000;
  font-size: 10px;
}

body {
  color: #333;
  background-color: #fff;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #24416e;
  text-decoration: none;
}

a:hover, a:focus {
  color: #3763a8;
  text-decoration: underline;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

.img-responsive, .thumbnail > img, .thumbnail a > img, .carousel-inner > .item > img, .carousel-inner > .item > a > img {
  max-width: 100%;
  height: auto;
  display: block;
}

.img-rounded {
  border-radius: 6px;
}

.img-thumbnail {
  -o-transition: all .2s ease-in-out;
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
  line-height: 1.42857;
  transition: all .2s ease-in-out;
  display: inline-block;
}

.img-circle {
  border-radius: 50%;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

[role="button"] {
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: inherit;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  color: #777;
  font-weight: 400;
  line-height: 1;
}

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h1 .small, .h1 .small, h2 .small, .h2 .small, h3 .small, .h3 .small {
  font-size: 65%;
}

h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small, h4 .small, .h4 .small, h5 .small, .h5 .small, h6 .small, .h6 .small {
  font-size: 75%;
}

h1, .h1 {
  font-size: 200%;
}

h2, .h2 {
  font-size: 175%;
}

h3, .h3 {
  font-size: 150%;
}

h4, .h4 {
  font-size: 125%;
}

h5, .h5 {
  font-size: 100%;
}

h6, .h6 {
  font-size: 90%;
}

p {
  margin: 0 0 10px;
}

.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .lead {
    font-size: 21px;
  }
}

small, .small {
  font-size: 85%;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-muted {
  color: #777;
}

.text-primary {
  color: #3763a8;
}

a.text-primary:hover, a.text-primary:focus {
  color: #2a4c82;
}

.text-success {
  color: #3c763d;
}

a.text-success:hover, a.text-success:focus {
  color: #2b542c;
}

.text-info {
  color: #31708f;
}

a.text-info:hover, a.text-info:focus {
  color: #245269;
}

.text-warning {
  color: #8a6d3b;
}

a.text-warning:hover, a.text-warning:focus {
  color: #66512c;
}

.text-danger {
  color: #a94442;
}

a.text-danger:hover, a.text-danger:focus {
  color: #843534;
}

.bg-primary {
  color: #fff;
  background-color: #3763a8;
}

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #2a4c82;
}

.bg-success {
  background-color: #dff0d8;
}

a.bg-success:hover, a.bg-success:focus {
  background-color: #c1e2b3;
}

.bg-info {
  background-color: #d9edf7;
}

a.bg-info:hover, a.bg-info:focus {
  background-color: #afd9ee;
}

.bg-warning {
  background-color: #fcf8e3;
}

a.bg-warning:hover, a.bg-warning:focus {
  background-color: #f7ecb5;
}

.bg-danger {
  background-color: #f2dede;
}

a.bg-danger:hover, a.bg-danger:focus {
  background-color: #e4b9b9;
}

.page-header {
  border-bottom: 1px solid #eee;
  margin: 40px 0 20px;
  padding-bottom: 9px;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
}

ul ul, ol ul, ul ol, ol ol {
  margin-bottom: 0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  margin-left: -5px;
  padding-left: 0;
  list-style: none;
}

.list-inline > li {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}

dt, dd {
  line-height: 1.42857;
}

dt {
  font-weight: 700;
}

dd {
  margin-left: 0;
}

@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .dl-horizontal dd {
    margin-left: 180px;
  }
}

abbr[title], abbr[data-original-title] {
  cursor: help;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

blockquote {
  border-left: 5px solid #eee;
  margin: 0 0 20px;
  padding: 10px 20px;
  font-size: 17.5px;
}

blockquote p:last-child, blockquote ul:last-child, blockquote ol:last-child {
  margin-bottom: 0;
}

blockquote footer, blockquote small, blockquote .small {
  color: #777;
  font-size: 80%;
  line-height: 1.42857;
  display: block;
}

blockquote footer:before, blockquote small:before, blockquote .small:before {
  content: "— ";
}

.blockquote-reverse, blockquote.pull-right {
  text-align: right;
  border-left: 0;
  border-right: 5px solid #eee;
  padding-left: 0;
  padding-right: 15px;
}

.blockquote-reverse footer:before, blockquote.pull-right footer:before, .blockquote-reverse small:before, blockquote.pull-right small:before, .blockquote-reverse .small:before, blockquote.pull-right .small:before {
  content: "";
}

.blockquote-reverse footer:after, blockquote.pull-right footer:after, .blockquote-reverse small:after, blockquote.pull-right small:after, .blockquote-reverse .small:after, blockquote.pull-right .small:after {
  content: " —";
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857;
}

code, kbd, pre, samp {
  font-family: Menlo, Monaco, Consolas, Courier New, monospace;
}

code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 90%;
}

kbd {
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 90%;
  box-shadow: inset 0 -1px #00000040;
}

kbd kbd {
  box-shadow: none;
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 0 10px;
  padding: 9.5px;
  font-size: 13px;
  line-height: 1.42857;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: #0000;
  border-radius: 0;
  padding: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row-no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.row-no-gutters [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.6667%;
}

.col-xs-10 {
  width: 83.3333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.6667%;
}

.col-xs-7 {
  width: 58.3333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.6667%;
}

.col-xs-4 {
  width: 33.3333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.6667%;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.6667%;
}

.col-xs-pull-10 {
  right: 83.3333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.6667%;
}

.col-xs-pull-7 {
  right: 58.3333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.6667%;
}

.col-xs-pull-4 {
  right: 33.3333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.6667%;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.6667%;
}

.col-xs-push-10 {
  left: 83.3333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.6667%;
}

.col-xs-push-7 {
  left: 58.3333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.6667%;
}

.col-xs-push-4 {
  left: 33.3333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.6667%;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.6667%;
}

.col-xs-offset-10 {
  margin-left: 83.3333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.6667%;
}

.col-xs-offset-7 {
  margin-left: 58.3333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.6667%;
}

.col-xs-offset-4 {
  margin-left: 33.3333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.6667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.6667%;
  }

  .col-sm-10 {
    width: 83.3333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.6667%;
  }

  .col-sm-7 {
    width: 58.3333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.6667%;
  }

  .col-sm-4 {
    width: 33.3333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.6667%;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-pull-11 {
    right: 91.6667%;
  }

  .col-sm-pull-10 {
    right: 83.3333%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-8 {
    right: 66.6667%;
  }

  .col-sm-pull-7 {
    right: 58.3333%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-5 {
    right: 41.6667%;
  }

  .col-sm-pull-4 {
    right: 33.3333%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-2 {
    right: 16.6667%;
  }

  .col-sm-pull-1 {
    right: 8.33333%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-push-11 {
    left: 91.6667%;
  }

  .col-sm-push-10 {
    left: 83.3333%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-8 {
    left: 66.6667%;
  }

  .col-sm-push-7 {
    left: 58.3333%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-5 {
    left: 41.6667%;
  }

  .col-sm-push-4 {
    left: 33.3333%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-2 {
    left: 16.6667%;
  }

  .col-sm-push-1 {
    left: 8.33333%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }

  .col-sm-offset-11 {
    margin-left: 91.6667%;
  }

  .col-sm-offset-10 {
    margin-left: 83.3333%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-8 {
    margin-left: 66.6667%;
  }

  .col-sm-offset-7 {
    margin-left: 58.3333%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-5 {
    margin-left: 41.6667%;
  }

  .col-sm-offset-4 {
    margin-left: 33.3333%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-2 {
    margin-left: 16.6667%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.6667%;
  }

  .col-md-10 {
    width: 83.3333%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.6667%;
  }

  .col-md-7 {
    width: 58.3333%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.6667%;
  }

  .col-md-4 {
    width: 33.3333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.6667%;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-pull-11 {
    right: 91.6667%;
  }

  .col-md-pull-10 {
    right: 83.3333%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-8 {
    right: 66.6667%;
  }

  .col-md-pull-7 {
    right: 58.3333%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-5 {
    right: 41.6667%;
  }

  .col-md-pull-4 {
    right: 33.3333%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-2 {
    right: 16.6667%;
  }

  .col-md-pull-1 {
    right: 8.33333%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-push-11 {
    left: 91.6667%;
  }

  .col-md-push-10 {
    left: 83.3333%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-8 {
    left: 66.6667%;
  }

  .col-md-push-7 {
    left: 58.3333%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-5 {
    left: 41.6667%;
  }

  .col-md-push-4 {
    left: 33.3333%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-2 {
    left: 16.6667%;
  }

  .col-md-push-1 {
    left: 8.33333%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }

  .col-md-offset-11 {
    margin-left: 91.6667%;
  }

  .col-md-offset-10 {
    margin-left: 83.3333%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-8 {
    margin-left: 66.6667%;
  }

  .col-md-offset-7 {
    margin-left: 58.3333%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-5 {
    margin-left: 41.6667%;
  }

  .col-md-offset-4 {
    margin-left: 33.3333%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-2 {
    margin-left: 16.6667%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.6667%;
  }

  .col-lg-10 {
    width: 83.3333%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.6667%;
  }

  .col-lg-7 {
    width: 58.3333%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.6667%;
  }

  .col-lg-4 {
    width: 33.3333%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.6667%;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-pull-11 {
    right: 91.6667%;
  }

  .col-lg-pull-10 {
    right: 83.3333%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-8 {
    right: 66.6667%;
  }

  .col-lg-pull-7 {
    right: 58.3333%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-5 {
    right: 41.6667%;
  }

  .col-lg-pull-4 {
    right: 33.3333%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-2 {
    right: 16.6667%;
  }

  .col-lg-pull-1 {
    right: 8.33333%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-push-11 {
    left: 91.6667%;
  }

  .col-lg-push-10 {
    left: 83.3333%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-8 {
    left: 66.6667%;
  }

  .col-lg-push-7 {
    left: 58.3333%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-5 {
    left: 41.6667%;
  }

  .col-lg-push-4 {
    left: 33.3333%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-2 {
    left: 16.6667%;
  }

  .col-lg-push-1 {
    left: 8.33333%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }

  .col-lg-offset-11 {
    margin-left: 91.6667%;
  }

  .col-lg-offset-10 {
    margin-left: 83.3333%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-8 {
    margin-left: 66.6667%;
  }

  .col-lg-offset-7 {
    margin-left: 58.3333%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-5 {
    margin-left: 41.6667%;
  }

  .col-lg-offset-4 {
    margin-left: 33.3333%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-2 {
    margin-left: 16.6667%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }
}

table {
  background-color: #0000;
}

table col[class*="col-"] {
  float: none;
  display: table-column;
  position: static;
}

table td[class*="col-"], table th[class*="col-"] {
  float: none;
  display: table-cell;
  position: static;
}

caption {
  color: #777;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  vertical-align: top;
  border-top: 1px solid #ddd;
  padding: 8px;
  line-height: 1.42857;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 2px solid #ddd;
}

.table .table {
  background-color: #fff;
}

.table-condensed > thead > tr > th, .table-condensed > tbody > tr > th, .table-condensed > tfoot > tr > th, .table-condensed > thead > tr > td, .table-condensed > tbody > tr > td, .table-condensed > tfoot > tr > td {
  padding: 5px;
}

.table-bordered, .table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color: #f9f9f9;
}

.table-hover > tbody > tr:hover, .table > thead > tr > td.active, .table > tbody > tr > td.active, .table > tfoot > tr > td.active, .table > thead > tr > th.active, .table > tbody > tr > th.active, .table > tfoot > tr > th.active, .table > thead > tr.active > td, .table > tbody > tr.active > td, .table > tfoot > tr.active > td, .table > thead > tr.active > th, .table > tbody > tr.active > th, .table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}

.table-hover > tbody > tr > td.active:hover, .table-hover > tbody > tr > th.active:hover, .table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr:hover > .active, .table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}

.table > thead > tr > td.success, .table > tbody > tr > td.success, .table > tfoot > tr > td.success, .table > thead > tr > th.success, .table > tbody > tr > th.success, .table > tfoot > tr > th.success, .table > thead > tr.success > td, .table > tbody > tr.success > td, .table > tfoot > tr.success > td, .table > thead > tr.success > th, .table > tbody > tr.success > th, .table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover, .table-hover > tbody > tr > th.success:hover, .table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr:hover > .success, .table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}

.table > thead > tr > td.info, .table > tbody > tr > td.info, .table > tfoot > tr > td.info, .table > thead > tr > th.info, .table > tbody > tr > th.info, .table > tfoot > tr > th.info, .table > thead > tr.info > td, .table > tbody > tr.info > td, .table > tfoot > tr.info > td, .table > thead > tr.info > th, .table > tbody > tr.info > th, .table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover, .table-hover > tbody > tr > th.info:hover, .table-hover > tbody > tr.info:hover > td, .table-hover > tbody > tr:hover > .info, .table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}

.table > thead > tr > td.warning, .table > tbody > tr > td.warning, .table > tfoot > tr > td.warning, .table > thead > tr > th.warning, .table > tbody > tr > th.warning, .table > tfoot > tr > th.warning, .table > thead > tr.warning > td, .table > tbody > tr.warning > td, .table > tfoot > tr.warning > td, .table > thead > tr.warning > th, .table > tbody > tr.warning > th, .table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover, .table-hover > tbody > tr > th.warning:hover, .table-hover > tbody > tr.warning:hover > td, .table-hover > tbody > tr:hover > .warning, .table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}

.table > thead > tr > td.danger, .table > tbody > tr > td.danger, .table > tfoot > tr > td.danger, .table > thead > tr > th.danger, .table > tbody > tr > th.danger, .table > tfoot > tr > th.danger, .table > thead > tr.danger > td, .table > tbody > tr.danger > td, .table > tfoot > tr.danger > td, .table > thead > tr.danger > th, .table > tbody > tr.danger > th, .table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover, .table-hover > tbody > tr > th.danger:hover, .table-hover > tbody > tr.danger:hover > td, .table-hover > tbody > tr:hover > .danger, .table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    overflow-y: hidden;
  }

  .table-responsive > .table {
    margin-bottom: 0;
  }

  .table-responsive > .table > thead > tr > th, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }

  .table-responsive > .table-bordered {
    border: 0;
  }

  .table-responsive > .table-bordered > thead > tr > th:first-child, .table-responsive > .table-bordered > tbody > tr > th:first-child, .table-responsive > .table-bordered > tfoot > tr > th:first-child, .table-responsive > .table-bordered > thead > tr > td:first-child, .table-responsive > .table-bordered > tbody > tr > td:first-child, .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }

  .table-responsive > .table-bordered > thead > tr > th:last-child, .table-responsive > .table-bordered > tbody > tr > th:last-child, .table-responsive > .table-bordered > tfoot > tr > th:last-child, .table-responsive > .table-bordered > thead > tr > td:last-child, .table-responsive > .table-bordered > tbody > tr > td:last-child, .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }

  .table-responsive > .table-bordered > tbody > tr:last-child > th, .table-responsive > .table-bordered > tfoot > tr:last-child > th, .table-responsive > .table-bordered > tbody > tr:last-child > td, .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
  padding: 0;
  display: block;
}

label {
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  display: inline-block;
}

input[type="search"] {
  box-sizing: border-box;
  appearance: none;
}

input[type="radio"], input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9 ;
  line-height: normal;
}

input[type="radio"][disabled], input[type="checkbox"][disabled], input[type="radio"].disabled, input[type="checkbox"].disabled, fieldset[disabled] input[type="radio"], fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed;
}

input[type="file"] {
  display: block;
}

input[type="range"] {
  width: 100%;
  display: block;
}

select[multiple], select[size] {
  height: auto;
}

input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

output {
  color: #555;
  padding-top: 9px;
  font-size: 14px;
  line-height: 1.42857;
  display: block;
}

.form-control {
  width: 100%;
  height: 38px;
  color: #555;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #00000013;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #66afe999;
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.form-control::-ms-expand {
  background-color: #0000;
  border: 0;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  opacity: 1;
  background-color: #eee;
}

.form-control[disabled], fieldset[disabled] .form-control {
  cursor: not-allowed;
}

textarea.form-control {
  height: auto;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
    line-height: 38px;
  }

  input[type="date"].input-sm, input[type="time"].input-sm, input[type="datetime-local"].input-sm, input[type="month"].input-sm, .input-group-sm input[type="date"], .input-group-sm input[type="time"], .input-group-sm input[type="datetime-local"], .input-group-sm input[type="month"] {
    line-height: 30px;
  }

  input[type="date"].input-lg, input[type="time"].input-lg, input[type="datetime-local"].input-lg, input[type="month"].input-lg, .input-group-lg input[type="date"], .input-group-lg input[type="time"], .input-group-lg input[type="datetime-local"], .input-group-lg input[type="month"] {
    line-height: 46px;
  }
}

.form-group {
  margin-bottom: 15px;
}

.radio, .checkbox {
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  position: relative;
}

.radio.disabled label, .checkbox.disabled label, fieldset[disabled] .radio label, fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}

.radio label, .checkbox label {
  min-height: 20px;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 20px;
  font-weight: 400;
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
  margin-top: 4px \9 ;
  margin-left: -20px;
  position: absolute;
}

.radio + .radio, .checkbox + .checkbox {
  margin-top: -5px;
}

.radio-inline, .checkbox-inline {
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 20px;
  font-weight: 400;
  display: inline-block;
  position: relative;
}

.radio-inline.disabled, .checkbox-inline.disabled, fieldset[disabled] .radio-inline, fieldset[disabled] .checkbox-inline {
  cursor: not-allowed;
}

.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

.form-control-static {
  min-height: 34px;
  margin-bottom: 0;
  padding-top: 9px;
  padding-bottom: 9px;
}

.form-control-static.input-lg, .form-control-static.input-sm {
  padding-left: 0;
  padding-right: 0;
}

.input-sm {
  height: 30px;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

select.input-sm {
  height: 30px;
  line-height: 30px;
}

textarea.input-sm, select[multiple].input-sm {
  height: auto;
}

.form-group-sm .form-control {
  height: 30px;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px;
}

.form-group-sm textarea.form-control, .form-group-sm select[multiple].form-control {
  height: auto;
}

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.input-lg {
  height: 46px;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

select.input-lg {
  height: 46px;
  line-height: 46px;
}

textarea.input-lg, select[multiple].input-lg {
  height: auto;
}

.form-group-lg .form-control {
  height: 46px;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px;
}

.form-group-lg textarea.form-control, .form-group-lg select[multiple].form-control {
  height: auto;
}

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 47.5px;
}

.form-control-feedback {
  z-index: 2;
  width: 38px;
  height: 38px;
  text-align: center;
  pointer-events: none;
  line-height: 38px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.input-lg + .form-control-feedback, .input-group-lg + .form-control-feedback, .form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.input-sm + .form-control-feedback, .input-group-sm + .form-control-feedback, .form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
  color: #3c763d;
}

.has-success .form-control {
  border-color: #3c763d;
  box-shadow: inset 0 1px 1px #00000013;
}

.has-success .form-control:focus {
  border-color: #2b542c;
  box-shadow: inset 0 1px 1px #00000013, 0 0 6px #67b168;
}

.has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d;
}

.has-success .form-control-feedback {
  color: #3c763d;
}

.has-warning .help-block, .has-warning .control-label, .has-warning .radio, .has-warning .checkbox, .has-warning .radio-inline, .has-warning .checkbox-inline, .has-warning.radio label, .has-warning.checkbox label, .has-warning.radio-inline label, .has-warning.checkbox-inline label {
  color: #8a6d3b;
}

.has-warning .form-control {
  border-color: #8a6d3b;
  box-shadow: inset 0 1px 1px #00000013;
}

.has-warning .form-control:focus {
  border-color: #66512c;
  box-shadow: inset 0 1px 1px #00000013, 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b;
}

.has-warning .form-control-feedback {
  color: #8a6d3b;
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
  color: #a94442;
}

.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px #00000013;
}

.has-error .form-control:focus {
  border-color: #843534;
  box-shadow: inset 0 1px 1px #00000013, 0 0 6px #ce8483;
}

.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}

.has-error .form-control-feedback {
  color: #a94442;
}

.has-feedback label ~ .form-control-feedback {
  top: 25px;
}

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}

.help-block {
  color: #737373;
  margin-top: 5px;
  margin-bottom: 10px;
  display: block;
}

@media (min-width: 768px) {
  .form-inline .form-group {
    vertical-align: middle;
    margin-bottom: 0;
    display: inline-block;
  }

  .form-inline .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .form-inline .form-control-static {
    display: inline-block;
  }

  .form-inline .input-group {
    vertical-align: middle;
    display: inline-table;
  }

  .form-inline .input-group .input-group-addon, .form-inline .input-group .input-group-btn, .form-inline .input-group .form-control {
    width: auto;
  }

  .form-inline .input-group > .form-control {
    width: 100%;
  }

  .form-inline .control-label {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .form-inline .radio, .form-inline .checkbox {
    vertical-align: middle;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
  }

  .form-inline .radio label, .form-inline .checkbox label {
    padding-left: 0;
  }

  .form-inline .radio input[type="radio"], .form-inline .checkbox input[type="checkbox"] {
    margin-left: 0;
    position: relative;
  }

  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 9px;
}

.form-horizontal .radio, .form-horizontal .checkbox {
  min-height: 29px;
}

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 9px;
  }
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px;
  }

  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px;
  }
}

.btn {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #0000;
  border-radius: 0;
  margin-bottom: 0;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857;
  display: inline-block;
}

.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn:hover, .btn:focus, .btn.focus {
  color: #444;
  text-decoration: none;
}

.btn:active, .btn.active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px #00000020;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity= 65);
  opacity: .65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.btn.disabled, fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-default {
  color: #444;
  background-color: #fff;
  border-color: #444;
  line-height: 1;
}

.btn-default:focus, .btn-default.focus {
  color: #444;
  background-color: #e6e6e6;
  border-color: #040404;
}

.btn-default:hover {
  color: #444;
  background-color: #e6e6e6;
  border-color: #252525;
}

.btn-default:active, .btn-default.active, .open > .dropdown-toggle.btn-default {
  color: #444;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #252525;
}

.btn-default:active:hover, .btn-default.active:hover, .open > .dropdown-toggle.btn-default:hover, .btn-default:active:focus, .btn-default.active:focus, .open > .dropdown-toggle.btn-default:focus, .btn-default:active.focus, .btn-default.active.focus, .open > .dropdown-toggle.btn-default.focus {
  color: #444;
  background-color: #d4d4d4;
  border-color: #040404;
}

.btn-default.disabled:hover, .btn-default[disabled]:hover, fieldset[disabled] .btn-default:hover, .btn-default.disabled:focus, .btn-default[disabled]:focus, fieldset[disabled] .btn-default:focus, .btn-default.disabled.focus, .btn-default[disabled].focus, fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #444;
}

.btn-default .badge {
  color: #fff;
  background-color: #444;
}

.btn-default .fa {
  line-height: 1.4;
}

.btn-default:focus, .btn-default.focus {
  color: #444;
  background-color: #fff;
  border-color: #848484;
}

.btn-default:hover, .btn-default:active, .btn-default.active, .open > .dropdown-toggle.btn-default {
  color: #444;
  background-color: #fff;
  border-color: #636363;
}

.btn-default:active:hover, .btn-default.active:hover, .open > .dropdown-toggle.btn-default:hover, .btn-default:active:focus, .btn-default.active:focus, .open > .dropdown-toggle.btn-default:focus, .btn-default:active.focus, .btn-default.active.focus, .open > .dropdown-toggle.btn-default.focus {
  color: #444;
  background-color: #fff;
  border-color: #848484;
}

.btn-default:active, .btn-default.active, .open > .dropdown-toggle.btn-default {
  background-image: none;
}

.btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default, .btn-default.disabled:hover, .btn-default[disabled]:hover, fieldset[disabled] .btn-default:hover, .btn-default.disabled:focus, .btn-default[disabled]:focus, fieldset[disabled] .btn-default:focus, .btn-default.disabled.focus, .btn-default[disabled].focus, fieldset[disabled] .btn-default.focus, .btn-default.disabled:active, .btn-default[disabled]:active, fieldset[disabled] .btn-default:active, .btn-default.disabled.active, .btn-default[disabled].active, fieldset[disabled] .btn-default.active {
  background-color: #fff;
  border-color: #444;
}

.btn-default .badge {
  color: #fff;
  background-color: #444;
}

.btn-primary {
  color: #fff;
  background-color: #2a4c82;
  border-color: #182a48;
  line-height: 1;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #1e365b;
  border-color: #000;
}

.btn-primary:hover {
  color: #fff;
  background-color: #1e365b;
  border-color: #080f1a;
}

.btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #1e365b;
  background-image: none;
  border-color: #080f1a;
}

.btn-primary:active:hover, .btn-primary.active:hover, .open > .dropdown-toggle.btn-primary:hover, .btn-primary:active:focus, .btn-primary.active:focus, .open > .dropdown-toggle.btn-primary:focus, .btn-primary:active.focus, .btn-primary.active.focus, .open > .dropdown-toggle.btn-primary.focus {
  color: #fff;
  background-color: #152640;
  border-color: #000;
}

.btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover, .btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus, .btn-primary.disabled.focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary.focus {
  background-color: #2a4c82;
  border-color: #182a48;
}

.btn-primary .badge {
  color: #2a4c82;
  background-color: #fff;
}

.btn-primary .fa {
  line-height: 1.4;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #3763a8;
  border-color: #3763a8;
}

.btn-primary:hover, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #3763a8;
  border-color: #274676;
}

.btn-primary:active:hover, .btn-primary.active:hover, .open > .dropdown-toggle.btn-primary:hover, .btn-primary:active:focus, .btn-primary.active:focus, .open > .dropdown-toggle.btn-primary:focus, .btn-primary:active.focus, .btn-primary.active.focus, .open > .dropdown-toggle.btn-primary.focus {
  color: #fff;
  background-color: #4273c1;
  border-color: #3763a8;
}

.btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled, .btn-primary[disabled], fieldset[disabled] .btn-primary, .btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover, .btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus, .btn-primary.disabled.focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary.focus, .btn-primary.disabled:active, .btn-primary[disabled]:active, fieldset[disabled] .btn-primary:active, .btn-primary.disabled.active, .btn-primary[disabled].active, fieldset[disabled] .btn-primary.active {
  background-color: #2a4c82;
  border-color: #182a48;
}

.btn-primary .badge {
  color: #2a4c82;
  background-color: #fff;
}

.btn-success {
  color: #fff;
  background-color: #71a844;
  border-color: #4d722e;
  line-height: 1;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #598435;
  border-color: #0f1709;
}

.btn-success:hover {
  color: #fff;
  background-color: #598435;
  border-color: #2f461c;
}

.btn-success:active, .btn-success.active, .open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #598435;
  background-image: none;
  border-color: #2f461c;
}

.btn-success:active:hover, .btn-success.active:hover, .open > .dropdown-toggle.btn-success:hover, .btn-success:active:focus, .btn-success.active:focus, .open > .dropdown-toggle.btn-success:focus, .btn-success:active.focus, .btn-success.active.focus, .open > .dropdown-toggle.btn-success.focus {
  color: #fff;
  background-color: #486a2b;
  border-color: #0f1709;
}

.btn-success.disabled:hover, .btn-success[disabled]:hover, fieldset[disabled] .btn-success:hover, .btn-success.disabled:focus, .btn-success[disabled]:focus, fieldset[disabled] .btn-success:focus, .btn-success.disabled.focus, .btn-success[disabled].focus, fieldset[disabled] .btn-success.focus {
  background-color: #71a844;
  border-color: #4d722e;
}

.btn-success .badge {
  color: #71a844;
  background-color: #fff;
}

.btn-success .fa {
  line-height: 1.4;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #8bbf60;
  border-color: #8bbf60;
}

.btn-success:hover, .btn-success:active, .btn-success.active, .open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #8bbf60;
  border-color: #6a9d3f;
}

.btn-success:active:hover, .btn-success.active:hover, .open > .dropdown-toggle.btn-success:hover, .btn-success:active:focus, .btn-success.active:focus, .open > .dropdown-toggle.btn-success:focus, .btn-success:active.focus, .btn-success.active.focus, .open > .dropdown-toggle.btn-success.focus {
  color: #fff;
  background-color: #9ec979;
  border-color: #8bbf60;
}

.btn-success:active, .btn-success.active, .open > .dropdown-toggle.btn-success {
  background-image: none;
}

.btn-success.disabled, .btn-success[disabled], fieldset[disabled] .btn-success, .btn-success.disabled:hover, .btn-success[disabled]:hover, fieldset[disabled] .btn-success:hover, .btn-success.disabled:focus, .btn-success[disabled]:focus, fieldset[disabled] .btn-success:focus, .btn-success.disabled.focus, .btn-success[disabled].focus, fieldset[disabled] .btn-success.focus, .btn-success.disabled:active, .btn-success[disabled]:active, fieldset[disabled] .btn-success:active, .btn-success.disabled.active, .btn-success[disabled].active, fieldset[disabled] .btn-success.active {
  background-color: #71a844;
  border-color: #4d722e;
}

.btn-success .badge {
  color: #71a844;
  background-color: #fff;
}

.btn-info {
  color: #fff;
  background-color: #3c3c3c;
  border-color: #161616;
  line-height: 1;
}

.btn-info:focus, .btn-info.focus, .btn-info:hover {
  color: #fff;
  background-color: #222;
  border-color: #000;
}

.btn-info:active, .btn-info.active, .open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #222;
  background-image: none;
  border-color: #000;
}

.btn-info:active:hover, .btn-info.active:hover, .open > .dropdown-toggle.btn-info:hover, .btn-info:active:focus, .btn-info.active:focus, .open > .dropdown-toggle.btn-info:focus, .btn-info:active.focus, .btn-info.active.focus, .open > .dropdown-toggle.btn-info.focus {
  color: #fff;
  background-color: #111;
  border-color: #000;
}

.btn-info.disabled:hover, .btn-info[disabled]:hover, fieldset[disabled] .btn-info:hover, .btn-info.disabled:focus, .btn-info[disabled]:focus, fieldset[disabled] .btn-info:focus, .btn-info.disabled.focus, .btn-info[disabled].focus, fieldset[disabled] .btn-info.focus {
  background-color: #3c3c3c;
  border-color: #161616;
}

.btn-info .badge {
  color: #3c3c3c;
  background-color: #fff;
}

.btn-info .fa {
  line-height: 1.4;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #555;
  border-color: #555;
}

.btn-info:hover, .btn-info:active, .btn-info.active, .open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #555;
  border-color: #343434;
}

.btn-info:active:hover, .btn-info.active:hover, .open > .dropdown-toggle.btn-info:hover, .btn-info:active:focus, .btn-info.active:focus, .open > .dropdown-toggle.btn-info:focus, .btn-info:active.focus, .btn-info.active.focus, .open > .dropdown-toggle.btn-info.focus {
  color: #fff;
  background-color: #676767;
  border-color: #555;
}

.btn-info:active, .btn-info.active, .open > .dropdown-toggle.btn-info {
  background-image: none;
}

.btn-info.disabled, .btn-info[disabled], fieldset[disabled] .btn-info, .btn-info.disabled:hover, .btn-info[disabled]:hover, fieldset[disabled] .btn-info:hover, .btn-info.disabled:focus, .btn-info[disabled]:focus, fieldset[disabled] .btn-info:focus, .btn-info.disabled.focus, .btn-info[disabled].focus, fieldset[disabled] .btn-info.focus, .btn-info.disabled:active, .btn-info[disabled]:active, fieldset[disabled] .btn-info:active, .btn-info.disabled.active, .btn-info[disabled].active, fieldset[disabled] .btn-info.active {
  background-color: #3c3c3c;
  border-color: #161616;
}

.btn-info .badge {
  color: #3c3c3c;
  background-color: #fff;
}

.btn-warning {
  color: #fff;
  background-color: #82702a;
  border-color: #483e18;
  line-height: 1;
}

.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #5b4f1e;
  border-color: #000;
}

.btn-warning:hover {
  color: #fff;
  background-color: #5b4f1e;
  border-color: #1a1608;
}

.btn-warning:active, .btn-warning.active, .open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #5b4f1e;
  background-image: none;
  border-color: #1a1608;
}

.btn-warning:active:hover, .btn-warning.active:hover, .open > .dropdown-toggle.btn-warning:hover, .btn-warning:active:focus, .btn-warning.active:focus, .open > .dropdown-toggle.btn-warning:focus, .btn-warning:active.focus, .btn-warning.active.focus, .open > .dropdown-toggle.btn-warning.focus {
  color: #fff;
  background-color: #403715;
  border-color: #000;
}

.btn-warning.disabled:hover, .btn-warning[disabled]:hover, fieldset[disabled] .btn-warning:hover, .btn-warning.disabled:focus, .btn-warning[disabled]:focus, fieldset[disabled] .btn-warning:focus, .btn-warning.disabled.focus, .btn-warning[disabled].focus, fieldset[disabled] .btn-warning.focus {
  background-color: #82702a;
  border-color: #483e18;
}

.btn-warning .badge {
  color: #82702a;
  background-color: #fff;
}

.btn-warning .fa {
  line-height: 1.4;
}

.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #a89137;
  border-color: #a89137;
}

.btn-warning:hover, .btn-warning:active, .btn-warning.active, .open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #a89137;
  border-color: #766627;
}

.btn-warning:active:hover, .btn-warning.active:hover, .open > .dropdown-toggle.btn-warning:hover, .btn-warning:active:focus, .btn-warning.active:focus, .open > .dropdown-toggle.btn-warning:focus, .btn-warning:active.focus, .btn-warning.active.focus, .open > .dropdown-toggle.btn-warning.focus {
  color: #fff;
  background-color: #c1a742;
  border-color: #a89137;
}

.btn-warning:active, .btn-warning.active, .open > .dropdown-toggle.btn-warning {
  background-image: none;
}

.btn-warning.disabled, .btn-warning[disabled], fieldset[disabled] .btn-warning, .btn-warning.disabled:hover, .btn-warning[disabled]:hover, fieldset[disabled] .btn-warning:hover, .btn-warning.disabled:focus, .btn-warning[disabled]:focus, fieldset[disabled] .btn-warning:focus, .btn-warning.disabled.focus, .btn-warning[disabled].focus, fieldset[disabled] .btn-warning.focus, .btn-warning.disabled:active, .btn-warning[disabled]:active, fieldset[disabled] .btn-warning:active, .btn-warning.disabled.active, .btn-warning[disabled].active, fieldset[disabled] .btn-warning.active {
  background-color: #82702a;
  border-color: #483e18;
}

.btn-warning .badge {
  color: #82702a;
  background-color: #fff;
}

.btn-danger {
  color: #fff;
  background-color: #300b0b;
  border-color: #000;
  line-height: 1;
}

.btn-danger:focus, .btn-danger.focus, .btn-danger:hover {
  color: #fff;
  background-color: #070101;
  border-color: #000;
}

.btn-danger:active, .btn-danger.active, .open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #070101;
  background-image: none;
  border-color: #000;
}

.btn-danger:active:hover, .btn-danger.active:hover, .open > .dropdown-toggle.btn-danger:hover, .btn-danger:active:focus, .btn-danger.active:focus, .open > .dropdown-toggle.btn-danger:focus, .btn-danger:active.focus, .btn-danger.active.focus, .open > .dropdown-toggle.btn-danger.focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-danger.disabled:hover, .btn-danger[disabled]:hover, fieldset[disabled] .btn-danger:hover, .btn-danger.disabled:focus, .btn-danger[disabled]:focus, fieldset[disabled] .btn-danger:focus, .btn-danger.disabled.focus, .btn-danger[disabled].focus, fieldset[disabled] .btn-danger.focus {
  background-color: #300b0b;
  border-color: #000;
}

.btn-danger .badge {
  color: #300b0b;
  background-color: #fff;
}

.btn-danger .fa {
  line-height: 1.4;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #5a1414;
  border-color: #404040;
}

.btn-danger:hover, .btn-danger:active, .btn-danger.active, .open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #5a1414;
  border-color: #1f1f1f;
}

.btn-danger:active:hover, .btn-danger.active:hover, .open > .dropdown-toggle.btn-danger:hover, .btn-danger:active:focus, .btn-danger.active:focus, .open > .dropdown-toggle.btn-danger:focus, .btn-danger:active.focus, .btn-danger.active.focus, .open > .dropdown-toggle.btn-danger.focus {
  color: #fff;
  background-color: #771a1a;
  border-color: #404040;
}

.btn-danger:active, .btn-danger.active, .open > .dropdown-toggle.btn-danger {
  background-image: none;
}

.btn-danger.disabled, .btn-danger[disabled], fieldset[disabled] .btn-danger, .btn-danger.disabled:hover, .btn-danger[disabled]:hover, fieldset[disabled] .btn-danger:hover, .btn-danger.disabled:focus, .btn-danger[disabled]:focus, fieldset[disabled] .btn-danger:focus, .btn-danger.disabled.focus, .btn-danger[disabled].focus, fieldset[disabled] .btn-danger.focus, .btn-danger.disabled:active, .btn-danger[disabled]:active, fieldset[disabled] .btn-danger:active, .btn-danger.disabled.active, .btn-danger[disabled].active, fieldset[disabled] .btn-danger.active {
  background-color: #300b0b;
  border-color: #000;
}

.btn-danger .badge {
  color: #300b0b;
  background-color: #fff;
}

.btn-link {
  color: #24416e;
  border-radius: 0;
  font-weight: 400;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #0000;
}

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
  border-color: #0000;
}

.btn-link:hover, .btn-link:focus {
  color: #3763a8;
  background-color: #0000;
  text-decoration: underline;
}

.btn-link[disabled]:hover, fieldset[disabled] .btn-link:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:focus {
  color: #777;
  text-decoration: none;
}

.btn-lg, .btn-group-lg > .btn {
  border-radius: 0;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: 0;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.btn-xs, .btn-group-xs > .btn {
  border-radius: 0;
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  height: 0;
  transition-property: height, visibility;
  transition-duration: .35s;
  transition-timing-function: ease;
  position: relative;
  overflow: hidden;
}

.caret {
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9 ;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  margin-left: 2px;
  display: inline-block;
}

.dropup, .dropdown {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  min-width: 160px;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #00000026;
  border-radius: 4px;
  margin: 2px 0 0;
  padding: 5px 0;
  font-size: 14px;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 6px 12px #0000002d;
}

.dropdown-menu.pull-right {
  left: auto;
  right: 0;
}

.dropdown-menu .divider {
  height: 1px;
  background-color: #e5e5e5;
  margin: 9px 0;
  overflow: hidden;
}

.dropdown-menu > li > a {
  clear: both;
  color: #333;
  white-space: nowrap;
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.42857;
  display: block;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  color: #262626;
  background-color: #f5f5f5;
  text-decoration: none;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #fff;
  background-color: #3763a8;
  outline: 0;
  text-decoration: none;
}

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  color: #777;
}

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  cursor: not-allowed;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-color: #0000;
  background-image: none;
  text-decoration: none;
}

.open > .dropdown-menu {
  display: block;
}

.open > a {
  outline: 0;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-header {
  color: #777;
  white-space: nowrap;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857;
  display: block;
}

.dropdown-backdrop {
  z-index: 990;
  position: fixed;
  inset: 0;
}

.pull-right > .dropdown-menu {
  left: auto;
  right: 0;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9 ;
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
  margin-bottom: 2px;
  top: auto;
  bottom: 100%;
}

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    left: auto;
    right: 0;
  }

  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  float: left;
  position: relative;
}

.btn-group > .btn:hover, .btn-group-vertical > .btn:hover, .btn-group > .btn:focus, .btn-group-vertical > .btn:focus, .btn-group > .btn:active, .btn-group-vertical > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn.active {
  z-index: 2;
}

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  margin-left: -5px;
}

.btn-toolbar .btn, .btn-toolbar .btn-group, .btn-toolbar .input-group {
  float: left;
}

.btn-toolbar > .btn, .btn-toolbar > .btn-group, .btn-toolbar > .input-group {
  margin-left: 5px;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-group {
  float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group .dropdown-toggle:active, .btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
}

.btn-group > .btn-lg + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}

.btn-group.open .dropdown-toggle {
  box-shadow: inset 0 3px 5px #00000020;
}

.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn .caret {
  margin-left: 0;
}

.btn-lg .caret {
  border-width: 5px 5px 0;
}

.dropup .btn-lg .caret {
  border-width: 0 5px 5px;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group, .btn-group-vertical > .btn-group > .btn {
  float: none;
  width: 100%;
  max-width: 100%;
  display: block;
}

.btn-group-vertical > .btn-group > .btn {
  float: none;
}

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child), .btn-group-vertical > .btn:first-child:not(:last-child), .btn-group-vertical > .btn:last-child:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-justified {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  display: table;
}

.btn-group-justified > .btn, .btn-group-justified > .btn-group {
  float: none;
  width: 1%;
  display: table-cell;
}

.btn-group-justified > .btn-group .btn {
  width: 100%;
}

.btn-group-justified > .btn-group .dropdown-menu {
  left: auto;
}

[data-toggle="buttons"] > .btn input[type="radio"], [data-toggle="buttons"] > .btn-group > .btn input[type="radio"], [data-toggle="buttons"] > .btn input[type="checkbox"], [data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  border-collapse: separate;
  display: table;
  position: relative;
}

.input-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}

.input-group .form-control {
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  position: relative;
}

.input-group .form-control:focus {
  z-index: 3;
}

.input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn {
  height: 46px;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

select.input-group-lg > .form-control, select.input-group-lg > .input-group-addon, select.input-group-lg > .input-group-btn > .btn {
  height: 46px;
  line-height: 46px;
}

textarea.input-group-lg > .form-control, textarea.input-group-lg > .input-group-addon, textarea.input-group-lg > .input-group-btn > .btn, select[multiple].input-group-lg > .form-control, select[multiple].input-group-lg > .input-group-addon, select[multiple].input-group-lg > .input-group-btn > .btn {
  height: auto;
}

.input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn {
  height: 30px;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

select.input-group-sm > .form-control, select.input-group-sm > .input-group-addon, select.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  line-height: 30px;
}

textarea.input-group-sm > .form-control, textarea.input-group-sm > .input-group-addon, textarea.input-group-sm > .input-group-btn > .btn, select[multiple].input-group-sm > .form-control, select[multiple].input-group-sm > .input-group-addon, select[multiple].input-group-sm > .input-group-btn > .btn {
  height: auto;
}

.input-group-addon, .input-group-btn, .input-group .form-control {
  display: table-cell;
}

.input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child), .input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon, .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.input-group-addon.input-sm {
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 12px;
}

.input-group-addon.input-lg {
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 18px;
}

.input-group-addon input[type="radio"], .input-group-addon input[type="checkbox"] {
  margin-top: 0;
}

.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:first-child {
  border-right: 0;
}

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-addon:last-child {
  border-left: 0;
}

.input-group-btn {
  white-space: nowrap;
  font-size: 0;
  position: relative;
}

.input-group-btn > .btn {
  position: relative;
}

.input-group-btn > .btn + .btn {
  margin-left: -1px;
}

.input-group-btn > .btn:hover, .input-group-btn > .btn:focus, .input-group-btn > .btn:active {
  z-index: 2;
}

.input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}

.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px;
}

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.nav > li {
  display: block;
  position: relative;
}

.nav > li > a {
  padding: 10px 15px;
  display: block;
  position: relative;
}

.nav > li > a:hover, .nav > li > a:focus {
  background-color: #eee;
  text-decoration: none;
}

.nav > li.disabled > a {
  color: #777;
}

.nav > li.disabled > a:hover, .nav > li.disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #0000;
  text-decoration: none;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: #eee;
  border-color: #24416e;
}

.nav .nav-divider {
  height: 1px;
  background-color: #e5e5e5;
  margin: 9px 0;
  overflow: hidden;
}

.nav > li > a > img {
  max-width: none;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.nav-tabs > li > a {
  border: 1px solid #0000;
  border-radius: 4px 4px 0 0;
  margin-right: 2px;
  line-height: 1.42857;
}

.nav-tabs > li > a:hover {
  border-color: #eee #eee #ddd;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: #0000;
}

.nav-tabs.nav-justified {
  width: 100%;
  border-bottom: 0;
}

.nav-tabs.nav-justified > li {
  float: none;
}

.nav-tabs.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px;
}

.nav-tabs.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (min-width: 768px) {
  .nav-tabs.nav-justified > li {
    width: 1%;
    display: table-cell;
  }

  .nav-tabs.nav-justified > li > a {
    margin-bottom: 0;
  }
}

.nav-tabs.nav-justified > li > a {
  border-radius: 4px;
  margin-right: 0;
}

.nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
  border: 1px solid #ddd;
}

@media (min-width: 768px) {
  .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
  }

  .nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
    border-bottom-color: #fff;
  }
}

.nav-pills > li {
  float: left;
}

.nav-pills > li > a {
  border-radius: 4px;
}

.nav-pills > li + li {
  margin-left: 2px;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #3763a8;
}

.nav-stacked > li {
  float: none;
}

.nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0;
}

.nav-justified {
  width: 100%;
}

.nav-justified > li {
  float: none;
}

.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px;
}

.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (min-width: 768px) {
  .nav-justified > li {
    width: 1%;
    display: table-cell;
  }

  .nav-justified > li > a {
    margin-bottom: 0;
  }
}

.nav-tabs-justified {
  border-bottom: 0;
}

.nav-tabs-justified > li > a {
  border-radius: 4px;
  margin-right: 0;
}

.nav-tabs-justified > .active > a, .nav-tabs-justified > .active > a:hover, .nav-tabs-justified > .active > a:focus {
  border: 1px solid #ddd;
}

@media (min-width: 768px) {
  .nav-tabs-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
  }

  .nav-tabs-justified > .active > a, .nav-tabs-justified > .active > a:hover, .nav-tabs-justified > .active > a:focus {
    border-bottom-color: #fff;
  }
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.navbar {
  min-height: 50px;
  border: 1px solid #0000;
  margin-bottom: 20px;
  position: relative;
}

@media (min-width: 768px) {
  .navbar {
    border-radius: 4px;
  }

  .navbar-header {
    float: left;
  }
}

.navbar-collapse {
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid #0000;
  padding-left: 15px;
  padding-right: 15px;
  overflow-x: visible;
  box-shadow: inset 0 1px #ffffff1a;
}

.navbar-collapse.in {
  overflow-y: auto;
}

@media (min-width: 768px) {
  .navbar-collapse {
    width: auto;
    box-shadow: none;
    border-top: 0;
  }

  .navbar-collapse.collapse {
    padding-bottom: 0;
    height: auto !important;
    display: block !important;
    overflow: visible !important;
  }

  .navbar-collapse.in {
    overflow-y: visible;
  }

  .navbar-fixed-top .navbar-collapse, .navbar-static-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }
}

.navbar-fixed-top, .navbar-fixed-bottom {
  z-index: 1030;
  position: fixed;
  left: 0;
  right: 0;
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
  max-height: 340px;
}

@media (max-device-width: 480px) and (orientation: landscape) {
  .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: 200px;
  }
}

@media (min-width: 768px) {
  .navbar-fixed-top, .navbar-fixed-bottom {
    border-radius: 0;
  }
}

.navbar-fixed-top {
  border-width: 0 0 1px;
  top: 0;
}

.navbar-fixed-bottom {
  border-width: 1px 0 0;
  margin-bottom: 0;
  bottom: 0;
}

.container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
  margin-left: -15px;
  margin-right: -15px;
}

@media (min-width: 768px) {
  .container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
    margin-left: 0;
    margin-right: 0;
  }
}

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}

@media (min-width: 768px) {
  .navbar-static-top {
    border-radius: 0;
  }
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px;
  font-size: 18px;
  line-height: 20px;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-brand > img {
  display: block;
}

@media (min-width: 768px) {
  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: -15px;
  }
}

.navbar-toggle {
  float: right;
  background-color: #0000;
  background-image: none;
  border: 1px solid #0000;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 15px;
  padding: 9px 10px;
  position: relative;
}

.navbar-toggle:focus {
  outline: 0;
}

.navbar-toggle .icon-bar {
  width: 22px;
  height: 2px;
  border-radius: 1px;
  display: block;
}

.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}

@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
}

.navbar-nav {
  margin: 7.5px -15px;
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    float: none;
    width: auto;
    box-shadow: none;
    background-color: #0000;
    border: 0;
    margin-top: 0;
    position: static;
  }

  .navbar-nav .open .dropdown-menu > li > a, .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }

  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }

  .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
}

@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }

  .navbar-nav > li {
    float: left;
  }

  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.navbar-form {
  border-top: 1px solid #0000;
  border-bottom: 1px solid #0000;
  margin: 6px -15px;
  padding: 10px 15px;
  box-shadow: inset 0 1px #ffffff1a, 0 1px #ffffff1a;
}

@media (min-width: 768px) {
  .navbar-form .form-group {
    vertical-align: middle;
    margin-bottom: 0;
    display: inline-block;
  }

  .navbar-form .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .navbar-form .form-control-static {
    display: inline-block;
  }

  .navbar-form .input-group {
    vertical-align: middle;
    display: inline-table;
  }

  .navbar-form .input-group .input-group-addon, .navbar-form .input-group .input-group-btn, .navbar-form .input-group .form-control {
    width: auto;
  }

  .navbar-form .input-group > .form-control {
    width: 100%;
  }

  .navbar-form .control-label {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .navbar-form .radio, .navbar-form .checkbox {
    vertical-align: middle;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
  }

  .navbar-form .radio label, .navbar-form .checkbox label {
    padding-left: 0;
  }

  .navbar-form .radio input[type="radio"], .navbar-form .checkbox input[type="checkbox"] {
    margin-left: 0;
    position: relative;
  }

  .navbar-form .has-feedback .form-control-feedback {
    top: 0;
  }
}

@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 5px;
  }

  .navbar-form .form-group:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .navbar-form {
    width: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.navbar-nav > li > .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0;
}

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  border-radius: 4px 4px 0 0;
  margin-bottom: 0;
}

.navbar-btn {
  margin-top: 6px;
  margin-bottom: 6px;
}

.navbar-btn.btn-sm {
  margin-top: 10px;
  margin-bottom: 10px;
}

.navbar-btn.btn-xs {
  margin-top: 14px;
  margin-bottom: 14px;
}

.navbar-text {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .navbar-text {
    float: left;
    margin-left: 15px;
    margin-right: 15px;
  }

  .navbar-left {
    float: left !important;
  }

  .navbar-right {
    margin-right: -15px;
    float: right !important;
  }

  .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
}

.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
}

.navbar-default .navbar-brand {
  color: #777;
}

.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
  color: #5e5e5e;
  background-color: #0000;
}

.navbar-default .navbar-text, .navbar-default .navbar-nav > li > a {
  color: #777;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: #333;
  background-color: #0000;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: #555;
  background-color: #e7e7e7;
}

.navbar-default .navbar-nav > .disabled > a, .navbar-default .navbar-nav > .disabled > a:hover, .navbar-default .navbar-nav > .disabled > a:focus {
  color: #ccc;
  background-color: #0000;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: #555;
  background-color: #e7e7e7;
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #777;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #333;
    background-color: #0000;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #555;
    background-color: #e7e7e7;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #ccc;
    background-color: #0000;
  }
}

.navbar-default .navbar-toggle {
  border-color: #ddd;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: #ddd;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #888;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: #e7e7e7;
}

.navbar-default .navbar-link {
  color: #777;
}

.navbar-default .navbar-link:hover {
  color: #333;
}

.navbar-default .btn-link {
  color: #777;
}

.navbar-default .btn-link:hover, .navbar-default .btn-link:focus {
  color: #333;
}

.navbar-default .btn-link[disabled]:hover, fieldset[disabled] .navbar-default .btn-link:hover, .navbar-default .btn-link[disabled]:focus, fieldset[disabled] .navbar-default .btn-link:focus {
  color: #ccc;
}

.navbar-inverse {
  background-color: #222;
  border-color: #080808;
}

.navbar-inverse .navbar-brand {
  color: #9d9d9d;
}

.navbar-inverse .navbar-brand:hover, .navbar-inverse .navbar-brand:focus {
  color: #fff;
  background-color: #0000;
}

.navbar-inverse .navbar-text, .navbar-inverse .navbar-nav > li > a {
  color: #9d9d9d;
}

.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
  color: #fff;
  background-color: #0000;
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: #080808;
}

.navbar-inverse .navbar-nav > .disabled > a, .navbar-inverse .navbar-nav > .disabled > a:hover, .navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #444;
  background-color: #0000;
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  color: #fff;
  background-color: #080808;
}

@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #080808;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #080808;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #9d9d9d;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fff;
    background-color: #0000;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff;
    background-color: #080808;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #444;
    background-color: #0000;
  }
}

.navbar-inverse .navbar-toggle {
  border-color: #333;
}

.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
  background-color: #333;
}

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
  border-color: #101010;
}

.navbar-inverse .navbar-link {
  color: #9d9d9d;
}

.navbar-inverse .navbar-link:hover {
  color: #fff;
}

.navbar-inverse .btn-link {
  color: #9d9d9d;
}

.navbar-inverse .btn-link:hover, .navbar-inverse .btn-link:focus {
  color: #fff;
}

.navbar-inverse .btn-link[disabled]:hover, fieldset[disabled] .navbar-inverse .btn-link:hover, .navbar-inverse .btn-link[disabled]:focus, fieldset[disabled] .navbar-inverse .btn-link:focus {
  color: #444;
}

.breadcrumb {
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 8px 15px;
  list-style: none;
}

.breadcrumb > li {
  display: inline-block;
}

.breadcrumb > li + li:before {
  color: #ccc;
  content: "/ ";
  padding: 0 5px;
}

.breadcrumb > .active {
  color: #777;
}

.pagination {
  border-radius: 4px;
  margin: 20px 0;
  padding-left: 0;
  display: inline-block;
}

.pagination > li {
  display: inline;
}

.pagination > li > a, .pagination > li > span {
  float: left;
  color: #24416e;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
  padding: 8px 12px;
  line-height: 1.42857;
  text-decoration: none;
  position: relative;
}

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
  z-index: 2;
  color: #3763a8;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-left: 0;
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #3763a8;
  border-color: #3763a8;
}

.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.pagination-lg > li > a, .pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

.pagination-lg > li:first-child > a, .pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.pagination-lg > li:last-child > a, .pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.pagination-sm > li > a, .pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.pagination-sm > li:first-child > a, .pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.pagination-sm > li:last-child > a, .pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.pager {
  text-align: center;
  margin: 20px 0;
  padding-left: 0;
  list-style: none;
}

.pager li {
  display: inline;
}

.pager li > a, .pager li > span {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 5px 14px;
  display: inline-block;
}

.pager li > a:hover, .pager li > a:focus {
  background-color: #eee;
  text-decoration: none;
}

.pager .next > a, .pager .next > span {
  float: right;
}

.pager .previous > a, .pager .previous > span {
  float: left;
}

.pager .disabled > a, .pager .disabled > a:hover, .pager .disabled > a:focus, .pager .disabled > span {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
}

.label {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  display: inline;
}

a.label:hover, a.label:focus {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.label:empty {
  display: none;
}

.btn .label {
  position: relative;
  top: -1px;
}

.label-default {
  background-color: #777;
}

.label-default[href]:hover, .label-default[href]:focus {
  background-color: #5e5e5e;
}

.label-primary {
  background-color: #3763a8;
}

.label-primary[href]:hover, .label-primary[href]:focus {
  background-color: #2a4c82;
}

.label-success {
  background-color: #8bbf60;
}

.label-success[href]:hover, .label-success[href]:focus {
  background-color: #71a844;
}

.label-info {
  background-color: #555;
}

.label-info[href]:hover, .label-info[href]:focus {
  background-color: #3c3c3c;
}

.label-warning {
  background-color: #a89137;
}

.label-warning[href]:hover, .label-warning[href]:focus {
  background-color: #82702a;
}

.label-danger {
  background-color: #5a1414;
}

.label-danger[href]:hover, .label-danger[href]:focus {
  background-color: #300b0b;
}

.badge {
  min-width: 10px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.btn-xs .badge, .btn-group-xs > .btn .badge {
  padding: 1px 5px;
  top: 0;
}

a.badge:hover, a.badge:focus {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.list-group-item.active > .badge, .nav-pills > .active > a > .badge {
  color: #24416e;
  background-color: #fff;
}

.list-group-item > .badge {
  float: right;
}

.list-group-item > .badge + .badge {
  margin-right: 5px;
}

.nav-pills > li > a > .badge {
  margin-left: 3px;
}

.jumbotron {
  color: inherit;
  background-color: #eee;
  margin-bottom: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.jumbotron h1, .jumbotron .h1 {
  color: inherit;
}

.jumbotron p {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200;
}

.jumbotron > hr {
  border-top-color: #d5d5d5;
}

.container .jumbotron, .container-fluid .jumbotron {
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
}

.jumbotron .container {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .container .jumbotron, .container-fluid .jumbotron {
    padding-left: 60px;
    padding-right: 60px;
  }

  .jumbotron h1, .jumbotron .h1 {
    font-size: 63px;
  }
}

.thumbnail {
  -o-transition: border .2s ease-in-out;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 4px;
  line-height: 1.42857;
  transition: border .2s ease-in-out;
  display: block;
}

.thumbnail > img, .thumbnail a > img {
  margin-left: auto;
  margin-right: auto;
}

a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active {
  border-color: #24416e;
}

.thumbnail .caption {
  color: #333;
  padding: 9px;
}

.alert {
  border: 1px solid #0000;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 15px;
}

.alert h4 {
  color: inherit;
  margin-top: 0;
}

.alert .alert-link {
  font-weight: bold;
}

.alert > p, .alert > ul {
  margin-bottom: 0;
}

.alert > p + p {
  margin-top: 5px;
}

.alert-dismissable, .alert-dismissible {
  padding-right: 35px;
}

.alert-dismissable .close, .alert-dismissible .close {
  color: inherit;
  position: relative;
  top: -2px;
  right: -21px;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: inset 0 1px 2px #0000001a;
}

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  color: #fff;
  text-align: center;
  -o-transition: width .6s ease;
  background-color: #3763a8;
  font-size: 12px;
  line-height: 20px;
  transition: width .6s;
  box-shadow: inset 0 -1px #00000026;
}

.progress-striped .progress-bar, .progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 40px 40px;
}

.progress.active .progress-bar, .progress-bar.active {
  animation: 2s linear infinite progress-bar-stripes;
}

.progress-bar-success {
  background-color: #8bbf60;
}

.progress-striped .progress-bar-success {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-bar-info {
  background-color: #555;
}

.progress-striped .progress-bar-info {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-bar-warning {
  background-color: #a89137;
}

.progress-striped .progress-bar-warning {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-bar-danger {
  background-color: #5a1414;
}

.progress-striped .progress-bar-danger {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.media {
  margin-top: 15px;
}

.media:first-child {
  margin-top: 0;
}

.media, .media-body {
  zoom: 1;
  overflow: hidden;
}

.media-body {
  width: 10000px;
}

.media-object {
  display: block;
}

.media-object.img-thumbnail {
  max-width: none;
}

.media-right, .media > .pull-right {
  padding-left: 10px;
}

.media-left, .media > .pull-left {
  padding-right: 10px;
}

.media-left, .media-right, .media-body {
  vertical-align: top;
  display: table-cell;
}

.media-middle {
  vertical-align: middle;
}

.media-bottom {
  vertical-align: bottom;
}

.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.media-list {
  padding-left: 0;
  list-style: none;
}

.list-group {
  margin-bottom: 20px;
  padding-left: 0;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #ddd;
  margin-bottom: -1px;
  padding: 10px 15px;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.list-group-item:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-bottom: 0;
}

.list-group-item.disabled, .list-group-item.disabled:hover, .list-group-item.disabled:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #eee;
}

.list-group-item.disabled .list-group-item-heading, .list-group-item.disabled:hover .list-group-item-heading, .list-group-item.disabled:focus .list-group-item-heading {
  color: inherit;
}

.list-group-item.disabled .list-group-item-text, .list-group-item.disabled:hover .list-group-item-text, .list-group-item.disabled:focus .list-group-item-text {
  color: #777;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  z-index: 2;
  color: #fff;
  background-color: #3763a8;
  border-color: #3763a8;
}

.list-group-item.active .list-group-item-heading, .list-group-item.active:hover .list-group-item-heading, .list-group-item.active:focus .list-group-item-heading, .list-group-item.active .list-group-item-heading > small, .list-group-item.active:hover .list-group-item-heading > small, .list-group-item.active:focus .list-group-item-heading > small, .list-group-item.active .list-group-item-heading > .small, .list-group-item.active:hover .list-group-item-heading > .small, .list-group-item.active:focus .list-group-item-heading > .small {
  color: inherit;
}

.list-group-item.active .list-group-item-text, .list-group-item.active:hover .list-group-item-text, .list-group-item.active:focus .list-group-item-text {
  color: #c0d1eb;
}

a.list-group-item, button.list-group-item {
  color: #555;
}

a.list-group-item .list-group-item-heading, button.list-group-item .list-group-item-heading {
  color: #333;
}

a.list-group-item:hover, button.list-group-item:hover, a.list-group-item:focus, button.list-group-item:focus {
  color: #555;
  background-color: #f5f5f5;
  text-decoration: none;
}

button.list-group-item {
  width: 100%;
  text-align: left;
}

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}

a.list-group-item-success, button.list-group-item-success {
  color: #3c763d;
}

a.list-group-item-success .list-group-item-heading, button.list-group-item-success .list-group-item-heading {
  color: inherit;
}

a.list-group-item-success:hover, button.list-group-item-success:hover, a.list-group-item-success:focus, button.list-group-item-success:focus {
  color: #3c763d;
  background-color: #d0e9c6;
}

a.list-group-item-success.active, button.list-group-item-success.active, a.list-group-item-success.active:hover, button.list-group-item-success.active:hover, a.list-group-item-success.active:focus, button.list-group-item-success.active:focus {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}

a.list-group-item-info, button.list-group-item-info {
  color: #31708f;
}

a.list-group-item-info .list-group-item-heading, button.list-group-item-info .list-group-item-heading {
  color: inherit;
}

a.list-group-item-info:hover, button.list-group-item-info:hover, a.list-group-item-info:focus, button.list-group-item-info:focus {
  color: #31708f;
  background-color: #c4e3f3;
}

a.list-group-item-info.active, button.list-group-item-info.active, a.list-group-item-info.active:hover, button.list-group-item-info.active:hover, a.list-group-item-info.active:focus, button.list-group-item-info.active:focus {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}

a.list-group-item-warning, button.list-group-item-warning {
  color: #8a6d3b;
}

a.list-group-item-warning .list-group-item-heading, button.list-group-item-warning .list-group-item-heading {
  color: inherit;
}

a.list-group-item-warning:hover, button.list-group-item-warning:hover, a.list-group-item-warning:focus, button.list-group-item-warning:focus {
  color: #8a6d3b;
  background-color: #faf2cc;
}

a.list-group-item-warning.active, button.list-group-item-warning.active, a.list-group-item-warning.active:hover, button.list-group-item-warning.active:hover, a.list-group-item-warning.active:focus, button.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}

a.list-group-item-danger, button.list-group-item-danger {
  color: #a94442;
}

a.list-group-item-danger .list-group-item-heading, button.list-group-item-danger .list-group-item-heading {
  color: inherit;
}

a.list-group-item-danger:hover, button.list-group-item-danger:hover, a.list-group-item-danger:focus, button.list-group-item-danger:focus {
  color: #a94442;
  background-color: #ebcccc;
}

a.list-group-item-danger.active, button.list-group-item-danger.active, a.list-group-item-danger.active:hover, button.list-group-item-danger.active:hover, a.list-group-item-danger.active:focus, button.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;
}

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}

.panel {
  background-color: #fff;
  border: 1px solid #0000;
  border-radius: 4px;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px #0000000d;
}

.panel-body {
  padding: 15px;
}

.panel-heading {
  border-bottom: 1px solid #0000;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 10px 15px;
}

.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}

.panel-title {
  color: inherit;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

.panel-title > a, .panel-title > small, .panel-title > .small, .panel-title > small > a, .panel-title > .small > a {
  color: inherit;
}

.panel-footer {
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px 15px;
}

.panel > .list-group, .panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}

.panel > .list-group .list-group-item, .panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}

.panel > .list-group:first-child .list-group-item:first-child, .panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel > .list-group:last-child .list-group-item:last-child, .panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.panel-heading + .list-group .list-group-item:first-child, .list-group + .panel-footer {
  border-top-width: 0;
}

.panel > .table, .panel > .table-responsive > .table, .panel > .panel-collapse > .table {
  margin-bottom: 0;
}

.panel > .table caption, .panel > .table-responsive > .table caption, .panel > .panel-collapse > .table caption {
  padding-left: 15px;
  padding-right: 15px;
}

.panel > .table:first-child, .panel > .table-responsive:first-child > .table:first-child, .panel > .table:first-child > thead:first-child > tr:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: 3px;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: 3px;
}

.panel > .table:last-child, .panel > .table-responsive:last-child > .table:last-child, .panel > .table:last-child > tbody:last-child > tr:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 3px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 3px;
}

.panel > .panel-body + .table, .panel > .panel-body + .table-responsive, .panel > .table + .panel-body, .panel > .table-responsive + .panel-body {
  border-top: 1px solid #ddd;
}

.panel > .table > tbody:first-child > tr:first-child th, .panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}

.panel > .table-bordered, .panel > .table-responsive > .table-bordered {
  border: 0;
}

.panel > .table-bordered > thead > tr > th:first-child, .panel > .table-responsive > .table-bordered > thead > tr > th:first-child, .panel > .table-bordered > tbody > tr > th:first-child, .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child, .panel > .table-bordered > tfoot > tr > th:first-child, .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child, .panel > .table-bordered > thead > tr > td:first-child, .panel > .table-responsive > .table-bordered > thead > tr > td:first-child, .panel > .table-bordered > tbody > tr > td:first-child, .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child, .panel > .table-bordered > tfoot > tr > td:first-child, .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}

.panel > .table-bordered > thead > tr > th:last-child, .panel > .table-responsive > .table-bordered > thead > tr > th:last-child, .panel > .table-bordered > tbody > tr > th:last-child, .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child, .panel > .table-bordered > tfoot > tr > th:last-child, .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child, .panel > .table-bordered > thead > tr > td:last-child, .panel > .table-responsive > .table-bordered > thead > tr > td:last-child, .panel > .table-bordered > tbody > tr > td:last-child, .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child, .panel > .table-bordered > tfoot > tr > td:last-child, .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}

.panel > .table-bordered > thead > tr:first-child > td, .panel > .table-responsive > .table-bordered > thead > tr:first-child > td, .panel > .table-bordered > tbody > tr:first-child > td, .panel > .table-responsive > .table-bordered > tbody > tr:first-child > td, .panel > .table-bordered > thead > tr:first-child > th, .panel > .table-responsive > .table-bordered > thead > tr:first-child > th, .panel > .table-bordered > tbody > tr:first-child > th, .panel > .table-responsive > .table-bordered > tbody > tr:first-child > th, .panel > .table-bordered > tbody > tr:last-child > td, .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td, .panel > .table-bordered > tfoot > tr:last-child > td, .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td, .panel > .table-bordered > tbody > tr:last-child > th, .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th, .panel > .table-bordered > tfoot > tr:last-child > th, .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}

.panel > .table-responsive {
  border: 0;
  margin-bottom: 0;
}

.panel-group {
  margin-bottom: 20px;
}

.panel-group .panel {
  border-radius: 4px;
  margin-bottom: 0;
}

.panel-group .panel + .panel {
  margin-top: 5px;
}

.panel-group .panel-heading {
  border-bottom: 0;
}

.panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #ddd;
}

.panel-group .panel-footer {
  border-top: 0;
}

.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}

.panel-default {
  border-color: #ddd;
}

.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}

.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333;
}

.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}

.panel-primary {
  border-color: #3763a8;
}

.panel-primary > .panel-heading {
  color: #fff;
  background-color: #3763a8;
  border-color: #3763a8;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #3763a8;
}

.panel-primary > .panel-heading .badge {
  color: #3763a8;
  background-color: #fff;
}

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #3763a8;
}

.panel-success {
  border-color: #d6e9c6;
}

.panel-success > .panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6e9c6;
}

.panel-success > .panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d;
}

.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6e9c6;
}

.panel-info {
  border-color: #bce8f1;
}

.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1;
}

.panel-info > .panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f;
}

.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1;
}

.panel-warning {
  border-color: #faebcc;
}

.panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #faebcc;
}

.panel-warning > .panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b;
}

.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #faebcc;
}

.panel-danger {
  border-color: #ebccd1;
}

.panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ebccd1;
}

.panel-danger > .panel-heading .badge {
  color: #f2dede;
  background-color: #a94442;
}

.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ebccd1;
}

.embed-responsive {
  height: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.well {
  min-height: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 19px;
  box-shadow: inset 0 1px 1px #0000000d;
}

.well blockquote {
  border-color: #00000026;
}

.well-lg {
  border-radius: 6px;
  padding: 24px;
}

.well-sm {
  border-radius: 3px;
  padding: 9px;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  filter: alpha(opacity= 20);
  opacity: .2;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
}

.close:hover, .close:focus {
  color: #000;
  cursor: pointer;
  filter: alpha(opacity= 50);
  opacity: .5;
  text-decoration: none;
}

button.close {
  cursor: pointer;
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
}

.modal-open {
  overflow: hidden;
}

.modal {
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  -moz-transition: -moz-transform .3s ease-out;
  transition: transform .3s ease-out;
  transform: translate(0, -25%);
}

.modal.in .modal-dialog {
  transform: translate(0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  margin: 10px;
  position: relative;
}

.modal-content {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: 6px;
  outline: 0;
  position: relative;
  box-shadow: 0 3px 9px #00000080;
}

.modal-backdrop {
  z-index: 1040;
  background-color: #000;
  position: fixed;
  inset: 0;
}

.modal-backdrop.fade {
  filter: alpha(opacity= 0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity= 50);
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857;
}

.modal-body {
  padding: 15px;
  position: relative;
}

.modal-footer {
  text-align: right;
  border-top: 1px solid #e5e5e5;
  padding: 15px;
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  .modal-content {
    box-shadow: 0 5px 15px #00000080;
  }

  .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.tooltip {
  z-index: 1070;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  filter: alpha(opacity= 0);
  opacity: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.in {
  filter: alpha(opacity= 90);
  opacity: .9;
}

.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}

.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}

.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}

.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}

.tooltip.top .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #000;
  margin-left: -5px;
  bottom: 0;
  left: 50%;
}

.tooltip.top-left .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #000;
  margin-bottom: -5px;
  bottom: 0;
  right: 5px;
}

.tooltip.top-right .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #000;
  margin-bottom: -5px;
  bottom: 0;
  left: 5px;
}

.tooltip.right .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
  margin-top: -5px;
  top: 50%;
  left: 0;
}

.tooltip.left .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
  margin-top: -5px;
  top: 50%;
  right: 0;
}

.tooltip.bottom .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #000;
  margin-left: -5px;
  top: 0;
  left: 50%;
}

.tooltip.bottom-left .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #000;
  margin-top: -5px;
  top: 0;
  right: 5px;
}

.tooltip.bottom-right .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #000;
  margin-top: -5px;
  top: 0;
  left: 5px;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
  padding: 3px 8px;
}

.tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.popover {
  z-index: 1060;
  max-width: 276px;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: 6px;
  padding: 1px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  text-decoration: none;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 5px 10px #0003;
}

.popover.top {
  margin-top: -10px;
}

.popover.right {
  margin-left: 10px;
}

.popover.bottom {
  margin-top: 10px;
}

.popover.left {
  margin-left: -10px;
}

.popover > .arrow {
  border-width: 11px;
}

.popover > .arrow, .popover > .arrow:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #0000;
  display: block;
  position: absolute;
}

.popover > .arrow:after {
  content: "";
  border-width: 10px;
}

.popover.top > .arrow {
  border-top-color: #00000040;
  border-bottom-width: 0;
  margin-left: -11px;
  bottom: -11px;
  left: 50%;
}

.popover.top > .arrow:after {
  content: " ";
  border-top-color: #fff;
  border-bottom-width: 0;
  margin-left: -10px;
  bottom: 1px;
}

.popover.right > .arrow {
  border-left-width: 0;
  border-right-color: #00000040;
  margin-top: -11px;
  top: 50%;
  left: -11px;
}

.popover.right > .arrow:after {
  content: " ";
  border-left-width: 0;
  border-right-color: #fff;
  bottom: -10px;
  left: 1px;
}

.popover.bottom > .arrow {
  border-top-width: 0;
  border-bottom-color: #00000040;
  margin-left: -11px;
  top: -11px;
  left: 50%;
}

.popover.bottom > .arrow:after {
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
  margin-left: -10px;
  top: 1px;
}

.popover.left > .arrow {
  border-left-color: #00000040;
  border-right-width: 0;
  margin-top: -11px;
  top: 50%;
  right: -11px;
}

.popover.left > .arrow:after {
  content: " ";
  border-left-color: #fff;
  border-right-width: 0;
  bottom: -10px;
  right: 1px;
}

.popover-title {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
}

.popover-content {
  padding: 9px 14px;
}

.carousel {
  position: relative;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner > .item {
  -o-transition: .6s ease-in-out left;
  transition: left .6s ease-in-out;
  display: none;
  position: relative;
}

.carousel-inner > .item > img, .carousel-inner > .item > a > img {
  line-height: 1;
}

@media (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform .6s ease-in-out;
    -o-transition: -o-transform .6s ease-in-out;
    -moz-transition: -moz-transform .6s ease-in-out;
    backface-visibility: hidden;
    perspective: 1000px;
    transition: transform .6s ease-in-out;
  }

  .carousel-inner > .item.next, .carousel-inner > .item.active.right {
    left: 0;
    transform: translate3d(100%, 0, 0);
  }

  .carousel-inner > .item.prev, .carousel-inner > .item.active.left {
    left: 0;
    transform: translate3d(-100%, 0, 0);
  }

  .carousel-inner > .item.next.left, .carousel-inner > .item.prev.right, .carousel-inner > .item.active {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
}

.carousel-inner > .active, .carousel-inner > .next, .carousel-inner > .prev {
  display: block;
}

.carousel-inner > .active {
  left: 0;
}

.carousel-inner > .next, .carousel-inner > .prev {
  width: 100%;
  position: absolute;
  top: 0;
}

.carousel-inner > .next {
  left: 100%;
}

.carousel-inner > .prev {
  left: -100%;
}

.carousel-inner > .next.left, .carousel-inner > .prev.right {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

.carousel-control {
  width: 15%;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px #0009;
  filter: alpha(opacity= 50);
  opacity: .5;
  background-color: #0000;
  font-size: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.carousel-control.left {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#80000000", endColorstr= "#00000000", GradientType= 1);
  background-image: linear-gradient(to right, #00000080 0%, #0000 100%);
  background-repeat: repeat-x;
}

.carousel-control.right {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00000000", endColorstr= "#80000000", GradientType= 1);
  background-image: linear-gradient(to right, #0000 0%, #00000080 100%);
  background-repeat: repeat-x;
  left: auto;
  right: 0;
}

.carousel-control:hover, .carousel-control:focus {
  color: #fff;
  filter: alpha(opacity= 90);
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control .icon-prev, .carousel-control .icon-next, .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right {
  z-index: 5;
  margin-top: -10px;
  display: inline-block;
  position: absolute;
  top: 50%;
}

.carousel-control .icon-prev, .carousel-control .glyphicon-chevron-left {
  margin-left: -10px;
  left: 50%;
}

.carousel-control .icon-next, .carousel-control .glyphicon-chevron-right {
  margin-right: -10px;
  right: 50%;
}

.carousel-control .icon-prev, .carousel-control .icon-next {
  width: 20px;
  height: 20px;
  font-family: serif;
  line-height: 1;
}

.carousel-control .icon-prev:before {
  content: "‹";
}

.carousel-control .icon-next:before {
  content: "›";
}

.carousel-indicators {
  z-index: 15;
  width: 60%;
  text-align: center;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000 \9 ;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 10px;
  margin: 1px;
  display: inline-block;
}

.carousel-indicators .active {
  width: 12px;
  height: 12px;
  background-color: #fff;
  margin: 0;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px #0009;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

.carousel-caption .btn {
  text-shadow: none;
}

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-prev, .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }

  .carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
    margin-left: -10px;
  }

  .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
    margin-right: -10px;
  }

  .carousel-caption {
    padding-bottom: 30px;
    left: 20%;
    right: 20%;
  }

  .carousel-indicators {
    bottom: 20px;
  }
}

.clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after, .nav:before, .nav:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after, .modal-header:before, .modal-header:after, .modal-footer:before, .modal-footer:after {
  content: " ";
  display: table;
}

.clearfix:after, .dl-horizontal dd:after, .container:after, .container-fluid:after, .row:after, .form-horizontal .form-group:after, .btn-toolbar:after, .btn-group-vertical > .btn-group:after, .nav:after, .navbar:after, .navbar-header:after, .navbar-collapse:after, .pager:after, .panel-body:after, .modal-header:after, .modal-footer:after {
  clear: both;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}

.visible-xs, .visible-sm, .visible-md, .visible-lg, .visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table !important;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs, td.visible-xs {
    display: table-cell !important;
  }

  .visible-xs-block {
    display: block !important;
  }

  .visible-xs-inline {
    display: inline !important;
  }

  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table !important;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm, td.visible-sm {
    display: table-cell !important;
  }

  .visible-sm-block {
    display: block !important;
  }

  .visible-sm-inline {
    display: inline !important;
  }

  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table !important;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md, td.visible-md {
    display: table-cell !important;
  }

  .visible-md-block {
    display: block !important;
  }

  .visible-md-inline {
    display: inline !important;
  }

  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table !important;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg, td.visible-lg {
    display: table-cell !important;
  }

  .visible-lg-block {
    display: block !important;
  }

  .visible-lg-inline {
    display: inline !important;
  }

  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table !important;
  }

  tr.visible-print {
    display: table-row !important;
  }

  th.visible-print, td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }

  .hidden-print {
    display: none !important;
  }
}

.border-danger {
  border: 1px solid #5a1414;
}

.giant-header {
  font-size: 300%;
  display: block;
}

.light-text {
  color: #fff;
}

.editable {
  background-color: #ffffc81a;
}

html {
  width: 100%;
  background: #999;
}

body {
  width: 100%;
  background: #999 url("light-streaks.98723b7d.png") bottom / cover no-repeat fixed;
  font-family: Raleway, Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.6;
}

body > canvas {
  min-height: 900px;
  min-width: 100%;
}

body > .container-fluid {
  z-index: 100;
  position: relative;
}

div, header, footer, section, span, i, p, h1, h2, h3, h4, h5, h6 {
  position: relative;
}

.pages-nav, h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased;
  font-family: Raleway, Helvetica, Arial, sans-serif;
}

a {
  color: #598435;
  -o-transition: all .3s;
  transition: all .3s;
}

a:hover {
  color: #71a844;
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

.label {
  padding-top: .4em;
  line-height: .8;
  display: inline-block;
  top: -.1em;
}

.btn-lgr {
  font-size: 80%;
}

.page-nav-wrapper .pages-nav li {
  padding: 0;
}

.page-nav-wrapper .pages-nav a {
  background-color: #0000;
  padding: 0;
  display: block;
}

.page-nav-wrapper .pages-nav a.active, .page-nav-wrapper .pages-nav a:hover {
  text-shadow: 0 0 #0000;
  background-color: #0000;
}

.flip-btns a, a.flip-btn {
  perspective: 500px;
  -ms-perspective: 500px;
  padding: 0;
  display: inline-block;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.flip-btns a span, a.flip-btn span {
  -o-transition: all .3s cubic-bezier(.455, .03, .515, .955);
  padding: 1em 1.5em;
  transition: all .3s cubic-bezier(.455, .03, .515, .955);
  display: block;
  position: relative;
  transform: rotateX(0);
}

.flip-btns a.active span, a.flip-btn.active span, .flip-btns a:hover span, a.flip-btn:hover span {
  transform: rotateX(-360deg);
}

.pages-nav a {
  width: 100%;
  z-index: 90;
  border-left: 0 solid #bbb;
  position: relative;
}

.pages-nav a.active, .pages-nav a:hover {
  z-index: 100;
}

.pages-nav a span {
  color: #000;
  background-color: #eee;
}

.pages-nav a:hover span {
  color: #fff;
  background-color: #3763a8;
}

.pages-nav a.active span {
  color: #fff;
  background-color: #777;
}

.navbar-header .navbar-brand {
  height: auto;
  max-width: 75%;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  padding: 0;
  font-size: 230%;
}

@media screen and (min-width: 768px) {
  .navbar-header .navbar-brand {
    max-width: 100%;
  }
}

.pages-nav.navbar-header .navbar-brand a {
  color: #383838;
  padding: .4em;
  display: block;
}

.pages-nav.navbar-header .navbar-brand a span {
  color: #383838;
  line-height: 1;
  display: block;
  background-color: #0000 !important;
}

.pages-nav.navbar-header .navbar-brand a small {
  font-size: 12px;
  display: block;
}

.navbar-default {
  background-color: #0000;
}

.navbar-default .navbar-collapse {
  border: 0;
  overflow: visible;
}

@media (min-width: 768px) {
  .navbar-default .nav-container {
    width: 100%;
    justify-content: space-between;
    align-items: stretch;
    display: flex;
  }

  .navbar-default .inner.nav {
    height: 100%;
    align-items: stretch;
    display: flex;
  }

  .navbar-default .inner.nav > li > a {
    height: 100%;
  }

  .navbar-default .inner.nav > li > a > span {
    height: 100%;
    align-items: center;
    display: flex;
  }
}

.navbar-default .navbar-toggle .icon-bar {
  width: 100%;
}

.navbar-default .navbar-toggle .menu-text {
  margin-top: -1px;
  margin-bottom: -5px;
  display: block;
}

.btn .glyphicon-chevron-down {
  top: 3px;
}

.small-glyph {
  font-size: 80%;
}

.bubble-btn {
  width: 9em;
  height: 9em;
  vertical-align: middle;
  text-align: center;
  border-radius: 9em;
  margin-bottom: 10px;
  padding: .5em;
  display: inline-block;
}

.bubble-btn span {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  white-space: pre-wrap;
  padding: 40% 5%;
  display: block;
}

.challenge-list .btn {
  margin-bottom: .5em;
}

.starwrap {
  width: 100%;
  height: 100%;
  z-index: 98;
  position: absolute;
  top: 0;
  left: 0;
}

.star {
  width: 600px;
  height: 600px;
  background-color: #fff;
  display: block;
  position: absolute;
}

.main-wrapper {
  z-index: 100;
  min-height: 58vh;
}

.content-wrap {
  width: 100%;
}

body .main-wrapper .see-through {
  background-color: #0000;
}

#pjax-wrap {
  min-height: 100vh;
}

.add-floating-bg {
  min-height: 120vh;
}

@media screen and (min-width: 768px) {
  .add-floating-bg {
    font-size: 130%;
  }
}

.storybook .story {
  min-height: 97vh;
  color: #000;
  font-size: 120%;
}

.storybook .story header {
  margin-bottom: .7em;
  display: block;
}

.storybook .story header > * {
  margin-top: 0;
}

.storybook .story:last-child {
  min-height: 100vh;
}

.storybook .story:last-child .link-down {
  display: none;
}

.storybook .story .container {
  padding-top: 20vh;
  padding-bottom: 20vh;
}

@media screen and (min-width: 768px) {
  .storybook .story .container {
    padding-top: 25vh;
    padding-bottom: 20vh;
  }
}

.story .cta-nav {
  font-size: 95%;
  display: block;
}

.story .cta-nav .btn {
  margin: 10px;
  display: inline-block;
}

.story .cta-nav .btn span {
  color: #fff;
  background-color: #4d722e;
  padding: .5em 1em;
}

.story .cta-nav .btn:hover span {
  color: #fff;
  background-color: #71a844;
}

.story .container {
  z-index: 100;
}

.story:after {
  content: " ";
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 1;
  background-size: cover;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.story:before {
  content: " ";
  width: 100%;
  height: 400px;
  background-image: url("lower-fade.a411b70a.png");
  background-position: 0 100%;
  background-repeat: repeat-x;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.story-box {
  background-color: #fff;
  padding: 15px 30px;
}

@media screen and (min-width: 768px) {
  .story-box {
    background-color: #fff;
    padding: 30px 60px;
  }
}

.story-box .link-up, .story-box .link-down {
  width: 1.2em;
  height: 1.2em;
  color: #fff;
  padding: 0;
  font-size: 200%;
  position: absolute;
}

.story-box .link-up {
  top: -1.1em;
  left: 15px;
}

.story-box .link-down {
  top: -1.1em;
  right: 15px;
}

.main-wrapper > .container {
  z-index: 100;
  background-color: #fff;
  margin-top: 10em;
  margin-bottom: 15em;
  position: relative;
}

@media screen and (min-width: 768px) {
  .main-wrapper > .container {
    margin-left: auto;
    margin-right: auto;
  }
}

.main-wrapper > .floating-header {
  margin-bottom: 3em;
  padding-top: 30px;
  padding-bottom: 30px;
}

.main-wrapper > .floating-header h1 {
  margin-top: 0;
  margin-bottom: 0;
}

.main-wrapper > .fixed-cont {
  margin-top: 3em;
  margin-bottom: 3em;
}

#blogIndex .post-content {
  float: left;
  margin-bottom: 1em;
  display: block;
}

#blogIndex .post-content .btn {
  white-space: normal;
  width: 100%;
}

#blogIndex .post-content .flip-btn span {
  border: 0;
}

.blog-index-btn strong {
  padding-bottom: .5em;
  display: block;
}

br {
  display: none;
}

@media screen and (min-width: 768px) {
  br {
    display: inline-block;
  }
}

.post-content {
  font-size: 120%;
  line-height: 1.7;
}

article header h1 strong, article header h1 em {
  display: block;
}

article li {
  margin-bottom: 1em;
}

#workPage article li {
  margin-bottom: 0;
}

#workPage h2 {
  border-bottom: 1px solid #ccc;
  margin-top: 1em;
}

#workPage h3 {
  margin-bottom: 0;
}

#workPage h3 + h3 {
  margin-top: 0;
  margin-bottom: .2em;
}

.social-bubble {
  font-size: 130%;
}

.social-list .social-bubble .fa {
  border-radius: 0;
  padding-top: 0;
  font-size: 500%;
  line-height: 1.2;
  display: block;
}

.social-bubble span {
  padding-top: 0;
  padding-bottom: 0;
}

.social-list li {
  margin-bottom: 30px;
}

.main-padding {
  padding-top: 0;
  padding-bottom: 45px;
}

.deep-inset-content {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .deep-inset-content {
    padding: 15px 60px 60px;
  }
}

.deep-inset-content .post-content {
  width: 100%;
}

.post-content iframe {
  max-width: 100%;
  height: 40vh;
  width: 100vw;
  border: 0;
  margin: 15px -15px;
  display: block;
  box-shadow: 0 0 200px #0000001a;
}

@media screen and (min-width: 768px) {
  .post-content iframe {
    height: 620px;
    width: 100%;
    margin: 15px auto;
  }
}

.post-meta {
  border-top: 1px solid #0000001a;
  border-bottom: 1px solid #0000001a;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  padding-top: .5em;
  padding-bottom: .5em;
}

.fluid-width-video-wrapper {
  margin-bottom: 20px;
}

.page-nav-wrapper {
  background-color: #e0e0e0;
  border: 0 #0000;
  padding-left: 15px;
  padding-right: 15px;
  font-family: Raleway, Helvetica, Arial, sans-serif;
}

.navbar-collapse {
  box-shadow: 0 0 #0000;
}

.navbar-collapse:before {
  content: "";
  z-index: 1;
  height: 100vh;
  width: 100vw;
  min-height: 1500px;
  min-width: 320px;
  background-color: #ddd;
  transition: all .3s ease-out;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.navbar-collapse.closed:before {
  height: 0;
  min-height: 0;
}

@media screen and (min-width: 768px) {
  .navbar-collapse:before {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .page-nav-wrapper {
    font-size: 130%;
  }
}

.pages-nav {
  flex: 1;
  margin-bottom: 0;
}

.navbar-toggle {
  z-index: 110;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  margin-right: 0;
}

.share .icon {
  width: 1.4em;
  font-size: 140%;
  display: inline-block;
}

.site-footer {
  text-align: center;
  width: 100%;
  background-color: #e0e0e0;
  border-top: 0 #0000;
  margin-top: 0;
  padding-top: 3em;
  padding-bottom: 3em;
  position: absolute;
  bottom: -20em;
  left: 0;
}

.main-wrapper > .blog-page-wrap {
  margin-top: 10em;
}

.main-wrapper > .blog-page-wrap .back-link {
  margin-top: -2em;
}

.partition {
  width: 90%;
  height: 25px;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  margin-bottom: 20px;
  display: block;
  position: relative;
  top: -15px;
  box-shadow: 0 10px 18px -12px #000;
}

.ig-wrap {
  margin-bottom: 30px;
  margin-left: -30px;
  margin-right: -30px;
}

.ig-wrap img {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .ig-wrap {
    margin-left: 0;
    margin-right: 0;
  }

  .ig-wrap .container {
    width: 100%;
    padding-left: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .ig-wrap:before {
    content: " ";
    padding-top: 100%;
    display: block;
  }

  .instagram-media {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

#root {
  min-height: 500vh;
}

.App-header {
  background-color: #fff;
  margin-top: 30vh;
  padding: 20px 50px;
}

@media (min-width: 768px) {
  .navbar-default .navbar-collapse.navbar-right {
    justify-content: flex-end;
    display: flex !important;
  }
}

.header-404 {
  margin: 1em 0;
}

.static-section {
  position: static;
}

.edit-button {
  background-color: #fefefe;
  border: 0;
  border-radius: 0;
  padding: .2em .5em;
  position: absolute;
  top: -2em;
  right: -2em;
  box-shadow: 0 3px 8px -2px #999;
}

.editor-wrap {
  opacity: 1;
  transition: opacity .1s ease-out;
  position: relative;
}

.editor-is-loading {
  opacity: 0;
}

.ql-container {
  position: relative;
}

.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}

.ql-clipboard {
  height: 1px;
  position: absolute;
  top: 50%;
  left: -100000px;
  overflow-y: hidden;
}

.ql-clipboard p {
  margin: 0;
  padding: 0;
}

.ql-editor {
  box-sizing: border-box;
  height: 100%;
  tab-size: 4;
  text-align: left;
  word-wrap: break-word;
  outline: none;
  padding: 0;
  overflow-y: auto;
}

.ql-editor > * {
  cursor: text;
}

.ql-editor .ql-video {
  max-width: 100%;
  display: block;
}

.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}

.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}

.ql-editor .ql-bg-black {
  background-color: #000;
}

.ql-editor .ql-bg-red {
  background-color: #e60000;
}

.ql-editor .ql-bg-orange {
  background-color: #f90;
}

.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}

.ql-editor .ql-bg-green {
  background-color: #008a00;
}

.ql-editor .ql-bg-blue {
  background-color: #06c;
}

.ql-editor .ql-bg-purple {
  background-color: #93f;
}

.ql-editor .ql-color-white {
  color: #fff;
}

.ql-editor .ql-color-red {
  color: #e60000;
}

.ql-editor .ql-color-orange {
  color: #f90;
}

.ql-editor .ql-color-yellow {
  color: #ff0;
}

.ql-editor .ql-color-green {
  color: #008a00;
}

.ql-editor .ql-color-blue {
  color: #06c;
}

.ql-editor .ql-color-purple {
  color: #93f;
}

.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.ql-editor .ql-size-small {
  font-size: .75em;
}

.ql-editor .ql-size-large {
  font-size: 1.5em;
}

.ql-editor .ql-size-huge {
  font-size: 2.5em;
}

.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}

.ql-editor .ql-align-center {
  text-align: center;
}

.ql-editor .ql-align-justify {
  text-align: justify;
}

.ql-editor .ql-align-right {
  text-align: right;
}

.ql-editor.ql-blank:before {
  color: #0009;
  content: attr(data-placeholder);
  pointer-events: none;
  font-style: italic;
  position: absolute;
  left: 15px;
  right: 15px;
}

.ql-bubble.ql-toolbar:after, .ql-bubble .ql-toolbar:after {
  clear: both;
  content: "";
  display: table;
}

.ql-bubble.ql-toolbar button, .ql-bubble .ql-toolbar button {
  cursor: pointer;
  float: left;
  height: 24px;
  width: 28px;
  background: none;
  border: none;
  padding: 3px 5px;
  display: inline-block;
}

.ql-bubble.ql-toolbar button svg, .ql-bubble .ql-toolbar button svg {
  float: left;
  height: 100%;
}

.ql-bubble.ql-toolbar button:active:hover, .ql-bubble .ql-toolbar button:active:hover {
  outline: none;
}

.ql-bubble.ql-toolbar input.ql-image[type="file"], .ql-bubble .ql-toolbar input.ql-image[type="file"] {
  display: none;
}

.ql-bubble.ql-toolbar button:hover, .ql-bubble .ql-toolbar button:hover, .ql-bubble.ql-toolbar button:focus, .ql-bubble .ql-toolbar button:focus, .ql-bubble.ql-toolbar button.ql-active, .ql-bubble .ql-toolbar button.ql-active, .ql-bubble.ql-toolbar .ql-picker-label:hover, .ql-bubble .ql-toolbar .ql-picker-label:hover, .ql-bubble.ql-toolbar .ql-picker-label.ql-active, .ql-bubble .ql-toolbar .ql-picker-label.ql-active, .ql-bubble.ql-toolbar .ql-picker-item:hover, .ql-bubble .ql-toolbar .ql-picker-item:hover, .ql-bubble.ql-toolbar .ql-picker-item.ql-selected, .ql-bubble .ql-toolbar .ql-picker-item.ql-selected {
  color: #fff;
}

.ql-bubble.ql-toolbar button:hover .ql-fill, .ql-bubble .ql-toolbar button:hover .ql-fill, .ql-bubble.ql-toolbar button:focus .ql-fill, .ql-bubble .ql-toolbar button:focus .ql-fill, .ql-bubble.ql-toolbar button.ql-active .ql-fill, .ql-bubble .ql-toolbar button.ql-active .ql-fill, .ql-bubble.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-bubble .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-bubble.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-bubble .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-bubble.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-bubble .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-bubble.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-bubble .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-bubble.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-bubble .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #fff;
}

.ql-bubble.ql-toolbar button:hover .ql-stroke, .ql-bubble .ql-toolbar button:hover .ql-stroke, .ql-bubble.ql-toolbar button:focus .ql-stroke, .ql-bubble .ql-toolbar button:focus .ql-stroke, .ql-bubble.ql-toolbar button.ql-active .ql-stroke, .ql-bubble .ql-toolbar button.ql-active .ql-stroke, .ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-bubble.ql-toolbar button:hover .ql-stroke-miter, .ql-bubble .ql-toolbar button:hover .ql-stroke-miter, .ql-bubble.ql-toolbar button:focus .ql-stroke-miter, .ql-bubble .ql-toolbar button:focus .ql-stroke-miter, .ql-bubble.ql-toolbar button.ql-active .ql-stroke-miter, .ql-bubble .ql-toolbar button.ql-active .ql-stroke-miter, .ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #fff;
}

@media (pointer: coarse) {
  .ql-bubble.ql-toolbar button:hover:not(.ql-active), .ql-bubble .ql-toolbar button:hover:not(.ql-active) {
    color: #ccc;
  }

  .ql-bubble.ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-bubble .ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-bubble.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill, .ql-bubble .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: #ccc;
  }

  .ql-bubble.ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-bubble .ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-bubble.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter, .ql-bubble .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: #ccc;
  }
}

.ql-bubble, .ql-bubble * {
  box-sizing: border-box;
}

.ql-bubble .ql-hidden {
  display: none;
}

.ql-bubble .ql-out-bottom, .ql-bubble .ql-out-top {
  visibility: hidden;
}

.ql-bubble .ql-tooltip {
  position: absolute;
  transform: translateY(10px);
}

.ql-bubble .ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
}

.ql-bubble .ql-tooltip.ql-flip {
  transform: translateY(-10px);
}

.ql-bubble .ql-formats {
  vertical-align: middle;
  display: inline-block;
}

.ql-bubble .ql-formats:after {
  clear: both;
  content: "";
  display: table;
}

.ql-bubble .ql-stroke {
  fill: none;
  stroke: #ccc;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.ql-bubble .ql-stroke-miter {
  fill: none;
  stroke: #ccc;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.ql-bubble .ql-fill, .ql-bubble .ql-stroke.ql-fill {
  fill: #ccc;
}

.ql-bubble .ql-empty {
  fill: none;
}

.ql-bubble .ql-even {
  fill-rule: evenodd;
}

.ql-bubble .ql-thin, .ql-bubble .ql-stroke.ql-thin {
  stroke-width: 1px;
}

.ql-bubble .ql-transparent {
  opacity: .4;
}

.ql-bubble .ql-direction svg:last-child {
  display: none;
}

.ql-bubble .ql-direction.ql-active svg:last-child {
  display: inline;
}

.ql-bubble .ql-direction.ql-active svg:first-child {
  display: none;
}

.ql-bubble .ql-editor a {
  text-decoration: underline;
}

.ql-bubble .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 16px;
}

.ql-bubble .ql-editor code, .ql-bubble .ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
}

.ql-bubble .ql-editor pre {
  white-space: pre-wrap;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
}

.ql-bubble .ql-editor code {
  padding: 2px 4px;
  font-size: 85%;
}

.ql-bubble .ql-editor pre.ql-syntax {
  color: #f8f8f2;
  background-color: #23241f;
  overflow: visible;
}

.ql-bubble .ql-editor img {
  max-width: 100%;
}

.ql-bubble .ql-picker {
  color: #ccc;
  float: left;
  height: 24px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.ql-bubble .ql-picker-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding-left: 8px;
  padding-right: 2px;
  display: inline-block;
  position: relative;
}

.ql-bubble .ql-picker-label:before {
  line-height: 22px;
  display: inline-block;
}

.ql-bubble .ql-picker-options {
  min-width: 100%;
  white-space: nowrap;
  background-color: #444;
  padding: 4px 8px;
  display: none;
  position: absolute;
}

.ql-bubble .ql-picker-options .ql-picker-item {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-label {
  color: #777;
  z-index: 2;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #777;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #777;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options {
  z-index: 1;
  margin-top: -1px;
  display: block;
  top: 100%;
}

.ql-bubble .ql-color-picker, .ql-bubble .ql-icon-picker {
  width: 28px;
}

.ql-bubble .ql-color-picker .ql-picker-label, .ql-bubble .ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}

.ql-bubble .ql-color-picker .ql-picker-label svg, .ql-bubble .ql-icon-picker .ql-picker-label svg {
  right: 4px;
}

.ql-bubble .ql-icon-picker .ql-picker-options {
  padding: 4px 0;
}

.ql-bubble .ql-icon-picker .ql-picker-item {
  height: 24px;
  width: 24px;
  padding: 2px 4px;
}

.ql-bubble .ql-color-picker .ql-picker-options {
  width: 152px;
  padding: 3px 5px;
}

.ql-bubble .ql-color-picker .ql-picker-item {
  float: left;
  height: 16px;
  width: 16px;
  border: 1px solid #0000;
  margin: 2px;
  padding: 0;
}

.ql-bubble .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  width: 18px;
  margin-top: -9px;
  position: absolute;
  top: 50%;
  right: 0;
}

.ql-bubble .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=""]):before, .ql-bubble .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=""]):before, .ql-bubble .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=""]):before, .ql-bubble .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=""]):before, .ql-bubble .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=""]):before, .ql-bubble .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=""]):before {
  content: attr(data-label);
}

.ql-bubble .ql-picker.ql-header {
  width: 98px;
}

.ql-bubble .ql-picker.ql-header .ql-picker-label:before, .ql-bubble .ql-picker.ql-header .ql-picker-item:before {
  content: "Normal";
}

.ql-bubble .ql-picker.ql-header .ql-picker-label[data-value="1"]:before, .ql-bubble .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  content: "Heading 1";
}

.ql-bubble .ql-picker.ql-header .ql-picker-label[data-value="2"]:before, .ql-bubble .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  content: "Heading 2";
}

.ql-bubble .ql-picker.ql-header .ql-picker-label[data-value="3"]:before, .ql-bubble .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  content: "Heading 3";
}

.ql-bubble .ql-picker.ql-header .ql-picker-label[data-value="4"]:before, .ql-bubble .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  content: "Heading 4";
}

.ql-bubble .ql-picker.ql-header .ql-picker-label[data-value="5"]:before, .ql-bubble .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  content: "Heading 5";
}

.ql-bubble .ql-picker.ql-header .ql-picker-label[data-value="6"]:before, .ql-bubble .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  content: "Heading 6";
}

.ql-bubble .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  font-size: 2em;
}

.ql-bubble .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  font-size: 1.5em;
}

.ql-bubble .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  font-size: 1.17em;
}

.ql-bubble .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  font-size: 1em;
}

.ql-bubble .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  font-size: .83em;
}

.ql-bubble .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  font-size: .67em;
}

.ql-bubble .ql-picker.ql-font {
  width: 108px;
}

.ql-bubble .ql-picker.ql-font .ql-picker-label:before, .ql-bubble .ql-picker.ql-font .ql-picker-item:before {
  content: "Sans Serif";
}

.ql-bubble .ql-picker.ql-font .ql-picker-label[data-value="serif"]:before, .ql-bubble .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  content: "Serif";
}

.ql-bubble .ql-picker.ql-font .ql-picker-label[data-value="monospace"]:before, .ql-bubble .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  content: "Monospace";
}

.ql-bubble .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  font-family: Georgia, Times New Roman, serif;
}

.ql-bubble .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  font-family: Monaco, Courier New, monospace;
}

.ql-bubble .ql-picker.ql-size {
  width: 98px;
}

.ql-bubble .ql-picker.ql-size .ql-picker-label:before, .ql-bubble .ql-picker.ql-size .ql-picker-item:before {
  content: "Normal";
}

.ql-bubble .ql-picker.ql-size .ql-picker-label[data-value="small"]:before, .ql-bubble .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  content: "Small";
}

.ql-bubble .ql-picker.ql-size .ql-picker-label[data-value="large"]:before, .ql-bubble .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  content: "Large";
}

.ql-bubble .ql-picker.ql-size .ql-picker-label[data-value="huge"]:before, .ql-bubble .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  content: "Huge";
}

.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  font-size: 10px;
}

.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  font-size: 18px;
}

.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  font-size: 32px;
}

.ql-bubble .ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff;
}

.ql-bubble .ql-color-picker.ql-color .ql-picker-item {
  background-color: #000;
}

.ql-bubble .ql-toolbar .ql-formats {
  margin: 8px 12px 8px 0;
}

.ql-bubble .ql-toolbar .ql-formats:first-child {
  margin-left: 12px;
}

.ql-bubble .ql-color-picker svg {
  margin: 1px;
}

.ql-bubble .ql-color-picker .ql-picker-item.ql-selected, .ql-bubble .ql-color-picker .ql-picker-item:hover {
  border-color: #fff;
}

.ql-bubble .ql-tooltip {
  color: #fff;
  background-color: #444;
  border-radius: 25px;
}

.ql-bubble .ql-tooltip-arrow {
  content: " ";
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  margin-left: -6px;
  display: block;
  position: absolute;
  left: 50%;
}

.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
  border-bottom: 6px solid #444;
  top: -6px;
}

.ql-bubble .ql-tooltip.ql-flip .ql-tooltip-arrow {
  border-top: 6px solid #444;
  bottom: -6px;
}

.ql-bubble .ql-tooltip.ql-editing .ql-tooltip-editor {
  display: block;
}

.ql-bubble .ql-tooltip.ql-editing .ql-formats {
  visibility: hidden;
}

.ql-bubble .ql-tooltip-editor {
  display: none;
}

.ql-bubble .ql-tooltip-editor input[type="text"] {
  color: #fff;
  height: 100%;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  padding: 10px 20px;
  font-size: 13px;
  position: absolute;
}

.ql-bubble .ql-tooltip-editor a {
  position: absolute;
  top: 10px;
  right: 20px;
}

.ql-bubble .ql-tooltip-editor a:before {
  color: #ccc;
  content: "×";
  font-size: 16px;
  font-weight: bold;
}

.ql-container.ql-bubble:not(.ql-disabled) a {
  white-space: nowrap;
  position: relative;
}

.ql-container.ql-bubble:not(.ql-disabled) a:before {
  color: #fff;
  content: attr(href);
  z-index: 1;
  background-color: #444;
  border-radius: 15px;
  padding: 5px 15px;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none;
  top: -5px;
  overflow: hidden;
}

.ql-container.ql-bubble:not(.ql-disabled) a:after {
  content: " ";
  height: 0;
  width: 0;
  border-top: 6px solid #444;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  top: 0;
}

.ql-container.ql-bubble:not(.ql-disabled) a:before, .ql-container.ql-bubble:not(.ql-disabled) a:after {
  visibility: hidden;
  margin-left: 50%;
  transition: visibility 0s .2s;
  position: absolute;
  left: 0;
  transform: translate(-50%, -100%);
}

.ql-container.ql-bubble:not(.ql-disabled) a:hover:before, .ql-container.ql-bubble:not(.ql-disabled) a:hover:after {
  visibility: visible;
}

/*# sourceMappingURL=index.8318bdd1.css.map */
